import React, { useEffect, useRef, useState } from "react";
import "./MainLayout.css";
import Header from "../header/Header";
import { IconComponent } from "../common/Icon";
import { Drawer, DrawerContent } from "@progress/kendo-react-layout";
import { Outlet, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setLogindata } from "../redux/actions/action";

function MainLayout(props) {
  const pathname = window.location.pathname;
  const pathArray = pathname.split("/");
  const desiredPart = "/" + pathArray.slice(1, 3).join("/");

  const { loginData } = useSelector((state) => state.main);

  const adminSideBarList = [
    {
      text: "Dashboard",
      route: "/dashboard",
      iconName: "PersonWorkspace",
    },

    {
      text: "Reports",
      route: "/reports/home",
      iconName: "PersonWorkspace",
      subMenu: [
        {
          text: "Users",
          route: "/reports/users/users",
          iconName: "People",
        },
      ],
    },
    {
      text: "User Details",
      route: "/user/home",
      iconName: "PersonWorkspace",
      subMenu: [
        {
          text: "New Account",
          route: "/user/new-account",
          iconName: "UniversalAccessCircle",
        },
        {
          text: "Accounts",
          route: "/user/accounts/users",
          iconName: "UniversalAccessCircle",
        },
        {
          text: "Experience",
          route: "/user/experience/user-work-history",
          iconName: "FileText",
        },
        {
          text: "Rewards",
          route: "/user/rewards/user-quizzes",
          iconName: "Gift",
        },
        {
          text: "Chat",
          route: "/user/chat/chat-groups",
          iconName: "ChatLeft",
        },
        {
          text: "Users Support",
          route: "/user/users-support/users-support",
          iconName: "PeopleFill",
        },
        {
          text: "Users Devices",
          route: "/user/users-devices/users-devices",
          iconName: "Phone",
        },
      ],
    },

    {
      text: "Sites",
      route: "/sites/sites",
      iconName: "HouseDoor",
    },
    {
      text: "Products",
      route: "/products/products",
      iconName: "Person",
    },
    {
      text: "Companies",
      route: "/companies/companies",
      iconName: "Buildings",
    },
    {
      text: "Admin",
      route: "/admin/home",
      iconName: "PersonWorkspace",
      subMenu: [
        {
          text: "Rewards",
          route: "/admin/rewards/reward-activities",
          iconName: "Gift",
        },
        {
          text: "Quizzes",
          route: "/admin/quizzes/quizzes",
          iconName: "QuestionCircle",
        },
        {
          text: "User Roles",
          route: "/admin/user-roles/user-roles",
          iconName: "PersonPlus",
        },
        {
          text: "Trades",
          route: "/admin/trades/trades",
          iconName: "Database",
        },
        {
          text: "Company Roles",
          route: "/admin/company-roles/company-roles",
          iconName: "Buildings",
        },
        // {
        //   text: "Bi Info Channels ",
        //   route: "/admin/bill-info-channels/bill-info-channels",
        //   iconName: "FileText",
        // },
        {
          text: "Ads",
          route: "/admin/ads/ads",
          iconName: "Film",
        },
        // {
        //   text: "Send Notification",
        //   route: "/admin/notification/sendnotification",
        //   iconName: "PhoneVibrateFill",
        // },
        // {
        //   text: "Notification History",
        //   route: "/admin/notificationhistory/notificationhistory",
        //   iconName: "PhoneVibrateFill",
        // },
      ],
    },
    {
      text: "Posts",
      route: "/posts/home",
      iconName: "PhoneVibrateFill",
      subMenu: [
        {
          text: "Send Posts",
          route: "/posts/sendposts",
          iconName: "PhoneVibrateFill",
        },
        {
          text: "Posts Messages",
          route: "/posts/postsmessages",
          iconName: "Messenger",
        },
      ],
    },
    {
      text: "Notification",
      route: "/notifications/home",
      iconName: "PhoneVibrateFill",
      subMenu: [
        {
          text: "Send Notification",
          route: "/notifications/sendnotification",
          iconName: "PhoneVibrateFill",
        },
        {
          text: "Notification History",
          route: "/notifications/notificationhistory",
          iconName: "ClockHistory",
        },
        {
          text: "Notification Categories",
          route: "/notifications/notificationcategories",
          iconName: "BagFill",
        },
        {
          text: "Notification Messages",
          route: "/notifications/notificationmessage",
          iconName: "Messenger",
        },
      ],
    },
    {
      text: "Marketing",
      route: "/marketing/home ",
      iconName: "PersonWorkspace",
      subMenu: [
        {
          text: "BI Info Channels",
          route: "/marketing/bill-info-channels",
          iconName: "ExclamationCircle",
        },
        {
          text: "Features",
          route: "/marketing/features",
          iconName: "CardHeading",
        },
        {
          text: "FAQ",
          route: "/marketing/faq",
          iconName: "Bookmarks",
        },
      ],
    },
    {
      text: "Client Ads ",
      route: "/client/home ",
      iconName: "PersonWorkspace",
      subMenu: [
        {
          text: "Accounts",
          route: "/client/accounts/client-auth",
          iconName: "UniversalAccessCircle",
        },
      ],
    },
  ];

  const promoterSideBarList = [
    {
      text: "Dashboard",
      route: "/dashboard",
      iconName: "PersonWorkspace",
    },

    {
      text: "Reports",
      route: "/reports/home",
      iconName: "PersonWorkspace",
      subMenu: [
        {
          text: "Users",
          route: "/reports/users/users",
          iconName: "People",
        },
      ],
    },
    {
      text: "User",
      route: "/user/home",
      iconName: "PersonWorkspace",
      subMenu: [
        {
          text: "New Account",
          route: "/user/new-account",
          iconName: "UniversalAccessCircle",
        },
        {
          text: "Accounts",
          route: "/user/accounts/users",
          iconName: "UniversalAccessCircle",
        },
      ],
    },
  ];

  const listRef = useRef(null);
  const navigate = useNavigate();
  const [drawerExpand, setDrawerexpand] = useState(true);

  const dispatch = useDispatch();

  useEffect(() => {
    if (![4, 1].includes(loginData?.userRoleAuthId)) {
      dispatch(setLogindata({}));
      navigate("/", { replace: true });
    }
  }, [loginData?.userRoleAuthId]);

  const onDrawerSelect = (e) => {
    navigate(e.itemTarget.props.route);
  };

  const navigateToSubPage = (url, getIndex) => {
    navigate(url);
  };

  const navigateToPage = (url, getIndex) => {
    navigate(url);
  };

  const handleScroll = (scrollOffset) => {
    listRef.current.scrollLeft += scrollOffset;
  };

  const isSubMenuActive = (subMenu) => {
    let subMenuRouteArray = subMenu?.route.split("/");
    const desiredSubMenuPart = "/" + subMenuRouteArray.slice(1, 3).join("/");
    return desiredSubMenuPart == desiredPart?.trim();
    // if (subMenu?.route == desiredPart?.trim()) {
    //   return "active_sub_menu"
    // } else {
    //   return "inactive_sub_menu"
    // }
  };

  const getActiveStyleForMenuTitle = (data) => {
    console.log("dat===>", data);
    if (!data?.subMenu) {
      return { color: "blue" };
    } else {
      return {};
    }
  };

  const CustomItem = (props) => {
    return !drawerExpand ? (
      <div>
        {props?.text === "Dashboard" ? (
          <div className="dashboard-reposive">
            <div>
              <IconComponent
                className="drawer-first-icon-container"
                iconName={props.iconName}
                onClick={() => {
                  navigate(props?.route);
                }}
              />
            </div>
          </div>
        ) : (
          <div className="subroute-responsive">
            <span className={"k-svg-icon"}>
              <IconComponent
                onClick={() => {
                  navigate(props?.route);
                }}
                className="drawer-first-icon-container"
                iconName={props.iconName}
              />
            </span>
            {props?.subMenu?.length > 0 ? (
              <div>
                {props?.subMenu?.map((subItem, index) => (
                  <div onClick={() => navigateToSubPage(subItem?.route, index)}>
                    <IconComponent
                      className="drawer-first-icon-container sub-menu-item"
                      iconName={subItem?.iconName}
                      color={isSubMenuActive(subItem) ? "#116fa5" : ""}
                    />
                  </div>
                ))}
              </div>
            ) : (
              <></>
            )}
          </div>
        )}
      </div>
    ) : (
      <div>
        {props?.text === "Dashboard" ? (
          <>
            <div
              className="dashboard-main"
              onClick={() => {
                navigate(props?.route);
              }}
              style={{ cursor: "pointer" }}>
              <div>
                <b className="dashboard">Dashboard</b>
              </div>
            </div>
            <div
              style={{
                background: "#dedede",
                padding: "2px",
              }}></div>
          </>
        ) : (
          <div
            style={{
              backgroundColor: "#fff",

              cursor: "pointer",
            }}>
            <div className="main-route">
              <div></div>
              <b
                onClick={() => {
                  navigate(props?.route);
                }}
                className="sub-test"
                // style={getActiveStyleForMenuTitle(props)}
              >
                {props.text}
              </b>
            </div>

            {/* {props.text !== "Companies" ? ( */}
            <div className="companies-bottom"></div>
            {/* // ) : ( // <></>
            // )} */}
            {props?.subMenu?.length > 0 ? (
              <div>
                {props?.subMenu?.map((subItem, index) => (
                  <div
                    key={index}
                    onClick={() => navigateToSubPage(subItem?.route, index)}
                    className={
                      isSubMenuActive(subItem)
                        ? "active_sub_menu"
                        : "inactive_sub_menu"
                    }>
                    <div className="sub-route">
                      <div style={{ display: "flex", marginLeft: "15px" }}>
                        <IconComponent iconName={subItem.iconName} size="18" />
                      </div>

                      <div className="sub-test">{subItem.text}</div>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <></>
            )}
          </div>
        )}
      </div>
    );
  };

  const getActiveTextColorForMenuOfSubMenu = (item) => {
    let subMenuRouteArray = item?.route.split("/");
    if (subMenuRouteArray?.length === 3) {
      const desiredPart1 = "/" + pathArray.slice(1, 3).join("/");
      const desiredSubMenuPart = "/" + subMenuRouteArray.slice(1, 3).join("/");
      return desiredSubMenuPart == desiredPart1?.trim()
        ? { color: "#116fa5" }
        : {};
    } else {
      const desiredPart1 = "/" + pathArray.slice(1, 4).join("/");
      const desiredSubMenuPart = "/" + subMenuRouteArray.slice(1, 4).join("/");
      return desiredSubMenuPart == desiredPart1?.trim()
        ? { color: "#116fa5" }
        : {};
    }
  };

  return (
    <>
      <div className="main_screen_container">
        <div className="header_main_container">
          <Header
            drawerExpand={drawerExpand}
            setDrawerexpand={setDrawerexpand}
            subMenuName={props?.subMenuName}
          />
        </div>
        <div className="main_content_container">
          <div className="main_content_layout">
            <Drawer
              expanded={drawerExpand}
              position={"start"}
              className="main-drawer"
              mode={"push"}
              mini={true}
              items={
                loginData?.userRoleAuthId === 1
                  ? adminSideBarList.map((item, index) => ({
                      ...item,
                    }))
                  : loginData?.userRoleAuthId === 4
                  ? promoterSideBarList.map((item, index) => ({
                      ...item,
                    }))
                  : [].map((item, index) => ({
                      ...item,
                    }))
              }
              onSelect={onDrawerSelect}
              item={CustomItem}
              // style={{ height: '100%', background: 'red' }}
            >
              <DrawerContent
                className="list-content"
                style={{ padding: 0, borderRadius: "10px" }}>
                <section className="table-list" style={{ height: "100%" }}>
                  {props?.sidebarList ? (
                    <>
                      <div className="menu-list">
                        {props?.sidebarList?.[0]?.arrows && (
                          <div className="scroll-arrows">
                            <IconComponent
                              iconName={"ArrowLeftShort"}
                              className="arrow-right"
                              size={24}
                              onClick={() => handleScroll(-100)}
                            />
                          </div>
                        )}
                        <ul className="second-list" ref={listRef}>
                          {props?.sidebarList?.map((item, index) => (
                            <>
                              <li
                                className="menu"
                                key={"home"}
                                id="home"
                                onClick={() => {
                                  navigateToPage(item?.route, index);
                                }}>
                                <div
                                  className={`k-d-flex k-align-items-center k-flex-row menu`}
                                  key={index}>
                                  <span
                                    className="item-text"
                                    style={getActiveTextColorForMenuOfSubMenu(
                                      item
                                    )}>
                                    {item?.text}
                                  </span>
                                </div>
                              </li>
                            </>
                          ))}
                        </ul>
                        {props?.sidebarList?.[0]?.arrows && (
                          <div className="scroll-arrows">
                            <IconComponent
                              iconName={"ArrowRightShort"}
                              className="arrow-left"
                              size={24}
                              onClick={() => handleScroll(100)}
                            />
                          </div>
                        )}
                      </div>
                    </>
                  ) : (
                    <></>
                  )}

                  <Outlet />
                  {props?.children}
                  {/* <div
              style={{
                borderBottom: "0.2px solid #d3d3d3",
              }}></div> */}
                </section>
              </DrawerContent>
            </Drawer>
          </div>
        </div>
      </div>
    </>
  );
}
export default MainLayout;
