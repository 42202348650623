import React, { useState } from "react";
import { Card } from "@progress/kendo-react-layout";
import { FieldWrapper, Form } from "@progress/kendo-react-form";
import { Label } from "@progress/kendo-react-labels";
import { Loader } from "@progress/kendo-react-indicators";
import { Button } from "@progress/kendo-react-buttons";
import axios from "axios";
import { SHA256, enc } from "crypto-js";
import "./Login.css";
import { PostRequestCall } from "../apicall/PostRequest";
import {
  FORGOT_PASSWORD,
  RESET_PASSWORD,
  VALIDATE_RESET_CODE,
} from "../constant/Apipath";
import { useNavigate, useLocation } from "react-router";
import { GetrequestCall } from "../apicall/GetRequest";
import { IconComponent } from "../common/Icon";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [showLoader, setShowLoader] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfrimPassword, setShowConfrimPassword] = useState(false);
  const [email, setEmail] = useState(location?.state?.email ?? "");
  const [otp, setOTP] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordStrengthCount, setPasswordStrengthCount] = useState(0);
  const [error, setError] = useState(null);
  const [token, setToken] = useState(null);
  const [formType, setFormType] = useState("email");

  const iconChange = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const iconChangeForConfirmPasswrod = () => {
    setShowConfrimPassword((preValue) => !preValue);
  };

  const hash = (str) => {
    return SHA256(str).toString(enc.Hex);
  };

  const getOTP = async (e) => {
    try {
      e.preventDefault();
      if (!email) {
        setError("Please enter your email address.");
        return;
      }

      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(email)) {
        setError("Please enter a valid email address.");
        return;
      }

      setShowLoader(true);
      setError("");

      await GetrequestCall(FORGOT_PASSWORD + "/" + email, "");

      setShowLoader(false);
      setError("");
      setFormType("otp");
    } catch (error) {
      setShowLoader(false);
      setError(
        error?.data?.errorMessage ?? "An error occurred. Please try again."
      );
    }
  };

  const goback = () => {
    navigate("/");
  };

  const onBackClick = (type) => {
    setFormType("email");
    setError("");
  };

  const onHaveResetCodeClick = () => {
    if (!email) {
      setError("Please enter your email address.");
      return;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setError("Please enter a valid email address.");
      return;
    }

    setFormType("otp");
  };

  const verifyOTP = async (e) => {
    try {
      e.preventDefault();
      setShowLoader(true);
      setError("");

      // Check if OTP or password is empty
      if (!otp) {
        setShowLoader(false);
        return setError("Please enter the OTP.");
      }

      const userObj = {
        Email: email,
        resetCode: otp,
        NotifyToken:
          "dftc0JgeSGS4uYSxpBEwsP:APA91bFYT9kiUtE3tMY_2PblkaNLv-QM4Kvn9uxfukN71-kZwxGHMBDcNhGKMhFEfkwY2rrq7WUuK7MvL6XF1Ia3Wyqwt7iq-a3G5D2G_Kgrbx5JRdDyuFOKy92nA-V7NPYxbGWCZLVI",
        AppName: "BuilID",
        AppVersion: "3.9.2",
        MobileDeviceModel: "SM-E236B",
        Platform: "windows",
        PlatformVersion: "14",
        DeviceName: "samsung",
        SendWelcomeEmail: false,
      };

      await PostRequestCall(VALIDATE_RESET_CODE, userObj, "")
        .then(async (res) => {
          if (
            res?.data?.message === "Invalid Verification code !!!!" ||
            res?.data?.message === "Incorrect Email Address !!!" ||
            res?.status === 404
          ) {
            setShowLoader(false);
            return setError(
              res?.data?.message ?? "Verification code is Invalid!"
            );
          } else {
            setShowLoader(false);
            setError("");
            setToken(res?.data?.data?.token ?? null);
            setFormType("password");
          }
        })
        .catch((err) => {
          setShowLoader(false);
          return setError("Verification code is Invalid!");
        });
    } catch (error) {
      setShowLoader(false);
      return setError("An error occurred while verifying OTP.");
    }
  };

  const updatePassword = async () => {
    try {
      setShowLoader(true);
      setError("");
      const obj = {
        email: email,
        password: hash(password),
      };

      await PostRequestCall(RESET_PASSWORD, obj, token)
        .then(async (res) => {
          navigate("/");
        })
        .catch((err) => {
          setShowLoader(false);
          return setError("something wrong please try again later");
        });
    } catch (error) {
      setShowLoader(false);
      return setError("something wrong please try again later");
    }
  };

  const getPasswordStrength = () => {
    let strength = "";
    if (passwordStrengthCount === 0) strength = "";
    else if (passwordStrengthCount === 1) strength = "Weak";
    else if (passwordStrengthCount > 1 && passwordStrengthCount < 4)
      strength = "Medium";
    else if (passwordStrengthCount === 4) strength = "Strong";
    return strength;
  };

  const showPasswordForm = () => {
    return (
      <>
        <Form
          render={(formRenderProps) => (
            <FieldWrapper>
              <h5 className="login-text k-mb-2">
                <b>Forget Password</b>
              </h5>
              <p style={{ color: "#898989", marginTop: "3px" }}>
                Enter the new password
              </p>
              <div className="form-group" style={{ position: "relative" }}>
                <Label className="auth-lb">New Password</Label>
                <input
                  className="auth-ip password-box"
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                  type={showPassword ? "text" : "password"}
                  value={password}
                  onChange={(e) => {
                    let count = 0;
                    if (e.target.value.length >= 6) count += 1;
                    if (e.target.value.match(/(?=.*\d)/)) count += 1;
                    if (e.target.value.match(/(?=.*[A-Z])/)) count += 1;
                    if (e.target.value.match(/(?=.*[a-z])/)) count += 1;
                    setPasswordStrengthCount(count);
                    setPassword(e.target.value);
                  }}
                />
                <div
                  style={{
                    position: "absolute",
                    right: "20px",
                    top: "65%",
                    transform: "translateY(-50%)",
                  }}>
                  <span
                    style={{
                      cursor: "pointer",
                      color: "#7A70BA",
                    }}
                    onClick={iconChange}>
                    {showPassword ? "Hide" : "Show"}
                  </span>
                </div>
              </div>
              <div>
                <div>
                  <div>
                    <span>Password strength</span>
                  </div>
                  <div>
                    <div>
                      <span
                        className="right"
                        style={{
                          color:
                            passwordStrengthCount === 1
                              ? "#FA0000"
                              : passwordStrengthCount > 1 &&
                                passwordStrengthCount < 4
                              ? "#FFBC0A"
                              : passwordStrengthCount === 4
                              ? "#28A745"
                              : "#8D8D8D",
                          display: "flex",
                          alignItems: "center",
                        }}>
                        {getPasswordStrength()}
                      </span>
                    </div>
                  </div>
                </div>

                <div
                  style={{
                    width: "100%",
                    height: "5px",
                    borderRadius: "4px",
                    backgroundColor: "#8D8D8D",
                    display: "flex",
                    alignItems: "center",
                  }}>
                  <div
                    style={{
                      borderRadius: "4px",
                      height: "5px",
                      width:
                        passwordStrengthCount === 1
                          ? "33%"
                          : passwordStrengthCount > 1 &&
                            passwordStrengthCount < 4
                          ? "66%"
                          : passwordStrengthCount === 4
                          ? "100%"
                          : 0,
                      backgroundColor:
                        passwordStrengthCount === 1
                          ? "#FA0000"
                          : passwordStrengthCount > 1 &&
                            passwordStrengthCount < 4
                          ? "#FFBC0A"
                          : passwordStrengthCount === 4
                          ? "#28A745"
                          : "#8D8D8D",
                      display: "flex",
                      alignItems: "center",
                    }}></div>
                </div>

                <div>
                  <p
                    style={{
                      color:
                        password !== ""
                          ? password.length >= 6
                            ? "#28A745"
                            : "#FA0000"
                          : "#8D8D8D",
                      display: "flex",
                      alignItems: "center",
                    }}>
                    {password === "" || password.length >= 6 ? (
                      <IconComponent
                        iconName={"Check"}
                        size={20}
                        style={{
                          color: password.length >= 6 ? "#28A745" : "#8D8D8D",
                        }}
                      />
                    ) : (
                      <IconComponent
                        iconName={"X"}
                        size={20}
                        style={{ color: "#FA0000" }}
                      />
                    )}{" "}
                    At least 6 characters
                  </p>

                  <p
                    style={{
                      color:
                        password !== ""
                          ? password.match(/(?=.*\d)/)
                            ? "#28A745"
                            : "#FA0000"
                          : "#8D8D8D",
                      display: "flex",
                      alignItems: "center",
                    }}>
                    {password === "" || password.match(/(?=.*\d)/) ? (
                      <IconComponent
                        iconName={"Check"}
                        size={20}
                        style={{
                          color: password.match(/(?=.*\d)/)
                            ? "#28A745"
                            : "#8D8D8D",
                        }}
                      />
                    ) : (
                      <IconComponent
                        iconName={"X"}
                        size={20}
                        style={{ color: "#FA0000" }}
                      />
                    )}{" "}
                    At least 1 number
                  </p>

                  <p
                    style={{
                      color:
                        password !== ""
                          ? password.match(/(?=.*[A-Z])/)
                            ? "#28A745"
                            : "#FA0000"
                          : "#8D8D8D",
                      display: "flex",
                      alignItems: "center",
                    }}>
                    {password === "" || password.match(/(?=.*[A-Z])/) ? (
                      <IconComponent
                        iconName={"Check"}
                        size={20}
                        style={{
                          color: password.match(/(?=.*[A-Z])/)
                            ? "#28A745"
                            : "#8D8D8D",
                        }}
                      />
                    ) : (
                      <IconComponent
                        iconName={"X"}
                        size={20}
                        style={{ color: "#FA0000" }}
                      />
                    )}{" "}
                    At least 1 capital letter
                  </p>

                  <p
                    style={{
                      color:
                        password !== ""
                          ? password.match(/(?=.*[a-z])/)
                            ? "#28A745"
                            : "#FA0000"
                          : "#8D8D8D",
                      display: "flex",
                      alignItems: "center",
                    }}>
                    {password === "" || password.match(/(?=.*[a-z])/) ? (
                      <IconComponent
                        iconName={"Check"}
                        size={20}
                        style={{
                          color: password.match(/(?=.*[a-z])/)
                            ? "#28A745"
                            : "#8D8D8D",
                        }}
                      />
                    ) : (
                      <IconComponent
                        iconName={"X"}
                        size={20}
                        style={{ color: "#FA0000" }}
                      />
                    )}{" "}
                    At least 1 lowercase letter
                  </p>
                </div>
              </div>

              <div className="form-group" style={{ position: "relative" }}>
                <Label className="auth-lb">Confrim Password</Label>
                <input
                  className="auth-ip password-box"
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                  type={showConfrimPassword ? "text" : "password"}
                  value={confirmPassword}
                  onChange={(e) => {
                    setConfirmPassword(e.target.value);
                  }}
                />
                <div
                  style={{
                    position: "absolute",
                    right: "20px",
                    top: "65%",
                    transform: "translateY(-50%)",
                  }}>
                  <span
                    style={{
                      cursor: "pointer",
                      color: "#7A70BA",
                    }}
                    onClick={iconChangeForConfirmPasswrod}>
                    {showConfrimPassword ? "Hide" : "Show"}
                  </span>
                </div>
              </div>

              <div>
                {confirmPassword === "" || password === confirmPassword ? (
                  <p
                    style={{
                      color:
                        password === confirmPassword && confirmPassword !== ""
                          ? "#28A745"
                          : "#8D8D8D",
                      display: "flex",
                      alignItems: "center",
                    }}>
                    <IconComponent
                      iconName={"Check"}
                      size={20}
                      style={{
                        color:
                          password === confirmPassword && confirmPassword !== ""
                            ? "#28A745"
                            : "#8D8D8D",
                      }}
                    />
                    Password
                  </p>
                ) : (
                  <p
                    style={{
                      color: "#FA0000",
                      display: "flex",
                      alignItems: "center",
                    }}>
                    <IconComponent
                      iconName={"X"}
                      size={20}
                      style={{ color: "#FA0000" }}
                    />
                    Password
                  </p>
                )}
              </div>
              {showLoader ? (
                <div className="k-d-flex k-justify-content-center k-align-items-center login-btn k-mt-2">
                  <Loader
                    type="pulsing"
                    size="medium"
                    style={{ color: "#ffffff" }}
                  />
                </div>
              ) : (
                <div
                  style={{ marginTop: "3rem", display: "flex", gap: "10px" }}>
                  <Button
                    className="login-btn"
                    type="submit"
                    onClick={onBackClick}>
                    Back
                  </Button>
                  <Button
                    className="login-btn"
                    type="submit"
                    onClick={updatePassword}
                    disabled={
                      passwordStrengthCount === 4 &&
                      password === confirmPassword
                        ? false
                        : true
                    }>
                    Reset Password
                  </Button>
                </div>
              )}
              {error && (
                <div style={{ color: "red", marginTop: "1rem" }}>{error}</div>
              )}
            </FieldWrapper>
          )}
        />
      </>
    );
  };

  const showOTPForm = () => {
    return (
      <>
        <Form
          render={(formRenderProps) => (
            <FieldWrapper>
              <h5 className="login-text k-mb-2">
                <b>Forget Password</b>
              </h5>
              <p style={{ color: "#898989", marginTop: "3px" }}>
                Enter reset code to continue
              </p>
              <div className="form-group">
                <Label className="auth-lb">Reset Code</Label>
                <input
                  name="otp"
                  className="auth-ip k-mt-2 k-p-0"
                  value={otp}
                  onChange={(e) => setOTP(e.target.value)}
                />
                <p className="forget_password_input_desc" onClick={getOTP}>
                  Didn't receive a code?
                </p>
              </div>

              {showLoader ? (
                <div className="k-d-flex k-justify-content-center k-align-items-center login-btn k-mt-2">
                  <Loader
                    type="pulsing"
                    size="medium"
                    style={{ color: "#ffffff" }}
                  />
                </div>
              ) : (
                <div
                  style={{ marginTop: "3rem", display: "flex", gap: "10px" }}>
                  <Button
                    className="login-btn"
                    type="submit"
                    onClick={onBackClick}>
                    Back
                  </Button>
                  <Button
                    className="login-btn"
                    type="submit"
                    onClick={verifyOTP}>
                    Verify
                  </Button>
                </div>
              )}
              {error && (
                <div style={{ color: "red", marginTop: "1rem" }}>{error}</div>
              )}
            </FieldWrapper>
          )}
        />
      </>
    );
  };

  const showEmailForm = () => {
    return (
      <>
        <Form
          render={(formRenderProps) => (
            <FieldWrapper>
              <h5 className="login-text k-mb-2">
                <b>Forgot your password?</b>
              </h5>
              <p style={{ color: "#898989", marginTop: "3px" }}>
                Enter your email to continue
              </p>
              <div className="form-group">
                <Label className="auth-lb">Email Address</Label>
                <input
                  name="email"
                  className="auth-ip k-mt-2 k-p-0"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <p
                className="forget_password_input_desc"
                onClick={onHaveResetCodeClick}>
                I have a reset code
              </p>

              {showLoader ? (
                <div className="k-d-flex k-justify-content-center k-align-items-center login-btn k-mt-2">
                  <Loader
                    type="pulsing"
                    size="medium"
                    style={{ color: "#ffffff" }}
                  />
                </div>
              ) : (
                <div
                  style={{ marginTop: "3rem", display: "flex", gap: "10px" }}>
                  <Button className="login-btn" type="submit" onClick={goback}>
                    Back
                  </Button>
                  <Button className="login-btn" type="submit" onClick={getOTP}>
                    Continue
                  </Button>
                </div>
              )}
              {error && (
                <div style={{ color: "red", marginTop: "1rem" }}>{error}</div>
              )}
            </FieldWrapper>
          )}
        />
      </>
    );
  };

  return (
    <section className="login-section">
      <Card className="login-card">
        {formType === "email" && showEmailForm()}
        {formType === "otp" && showOTPForm()}
        {formType === "password" && showPasswordForm()}
      </Card>
    </section>
  );
};

export default ForgotPassword;
