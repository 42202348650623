import { TextArea } from "@progress/kendo-react-inputs";
import { Label } from "@progress/kendo-react-labels";
import React, { useState } from "react";
import KendoButton from "../common/KendoButton";
import { Form } from "@progress/kendo-react-form";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { PostRequestCall } from "../apicall/PostRequest";
import {
  SAVE_POSTS_NOTIFICATION,
  SAVE_POSTS_NOTIFICATION_PICTURES,
} from "../constant/Apipath";
import { useSelector } from "react-redux";
import { IconComponent } from "../common/Icon";
import { Loader } from "@progress/kendo-react-indicators";

export default function SendPosts() {
  const { loginData } = useSelector((state) => state.main);
  const [showLoader, setShowLoader] = useState(false);
  const [postContent, setPostcontent] = useState({
    msgContent: "",
    imgFile: [],
  });

  const onContentchange = (e) => {
    setPostcontent({
      ...postContent,
      msgContent: e.target.value,
    });
  };

  const onPostPiture = (e) => {
    if (e?.target?.files?.length > 0) {
      let getImages = [...postContent.imgFile];
      getImages.push(e.target.files[0]);
      setPostcontent({
        ...postContent,
        imgFile: getImages,
      });
    }
    e.target.value = "";
  };

  const onSelectPostImage = (e) => {
    e.preventDefault();
    document.getElementById("post-profile").click();
  };

  const onRemovePostImg = (getIndex) => {
    let getUpdatelist = postContent?.imgFile.filter(
      (item, index) => index !== getIndex
    );
    setPostcontent({
      ...postContent,
      imgFile: getUpdatelist,
    });
  };

  const sendNotification = async () => {
    try {
      setShowLoader(true);
      const obj = {
        UserId: Number(loginData.userId),
        ReturnRecordId: true,
        ReturnRecordError: false,
        SaveList: [
          {
            Id: 0,
            FormatTypeId: 1,
            PostedByUserId: Number(loginData.userId),
            AppId: 25,
            AppRecordId: 0,
            Message:
              postContent?.msgContent !== ""
                ? postContent?.msgContent
                : "<<<picture>>>",
            ModifiedBy: Number(loginData.userId),
          },
        ],
      };

      const postResult = await PostRequestCall(
        SAVE_POSTS_NOTIFICATION,
        obj,
        loginData?.token
      );
      if (postResult?.status === 201) {
        let postId = postResult?.data?.data[0]?.objData?.id;
        onSavepostpicture(postId);
      }
    } catch (error) {
      setPostcontent({
        imgFile: [],
        msgContent: "",
      });
      setShowLoader(false);
    }
  };

  const onSavepostpicture = async (postId) => {
    try {
      for (let i = 0; i < postContent?.imgFile?.length; i++) {
        let data = new FormData();
        data.append("Id", 0);
        data.append("PostId", postId);
        data.append("ModifiedBy", Number(loginData.userId));
        data.append("Pictures", postContent?.imgFile[i]);
        let getResponse = await PostRequestCall(
          SAVE_POSTS_NOTIFICATION_PICTURES,
          data,
          loginData?.token,
          "formData"
        );
      }
      setShowLoader(false);
      setPostcontent({
        imgFile: [],
        msgContent: "",
      });
    } catch (error) {
      setPostcontent({
        imgFile: [],
        msgContent: "",
      });
      setShowLoader(false);
    }
  };

  if (showLoader) {
    return (
      <Loader
        type="converging-spinner"
        className="kendo-spinner"
        style={{
          display: "flex",
          justifyContent: "center",
          minHeight: "500px",
          alignItems: "center",
        }}
      />
    );
  }

  return (
    <div className="add_page_main_container">
      <div className="add_page_container">
        <div className="add_form_height_95">
          <div
            className="k-d-flex k-justify-content-end k-p-2"
            style={{ gap: "10px" }}>
            <KendoButton
              iconClass=""
              size={16}
              className="action-btn"
              buttonName="SEND"
              onClick={sendNotification}
            />
          </div>
          <div
            style={{
              borderBottom: "1px solid #f5f5f5",
              width: "100%",
            }}></div>
          <div style={{ width: "100%" }}>
            <Form
              render={() => (
                <GridLayout
                  rows={[{ height: 140 }, { height: 170 }]}
                  cols={[{ width: 700 }]}
                  gap={{ rows: 40, cols: 0 }}
                  style={{ padding: "16px" }}>
                  <GridLayoutItem col={1} row={1} className="form-inner">
                    <Label className="form-heading">Post:</Label>
                    <TextArea
                      id="body"
                      name="body"
                      value={postContent?.msgContent}
                      className="field-ip"
                      onChange={(e) => onContentchange(e)}
                      rows={5}
                      maxLength={150}
                      style={{ width: "100%", height: "130px" }}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem col={1} row={2} className="form-inner">
                    <Label className="form-heading">Add Photos :</Label>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        flexWrap: "wrap",
                        gap: "11px",
                      }}>
                      <GridLayoutItem col={2} row={1} className="form-inner">
                        <div
                          style={{
                            position: "relative",
                            cursor: "pointer",
                            border: "0.5px solid #d3d3d3",
                            padding: "5px",
                            display: "flex",
                            justifyContent: "center",
                            borderRadius: "4px",
                            gap: "10px",
                          }}
                          onClick={onSelectPostImage}>
                          <IconComponent iconName={"Image"} size={20} />
                          <span className="add-file-text">Choose image</span>
                        </div>
                      </GridLayoutItem>
                      <input
                        type="file"
                        hidden
                        accept=".png,.jpg"
                        id="post-profile"
                        style={{ display: "none" }}
                        onChange={(e) => {
                          onPostPiture(e);
                        }}
                      />
                      {postContent?.imgFile?.map((item, index) => (
                        <div className="k-d-flex post-img">
                          <div style={{ position: "relative" }}>
                            <img
                              className="user-img"
                              src={URL.createObjectURL(item)}
                              alt="Ad"
                            />
                            <button
                              onClick={() => onRemovePostImg(index)}
                              style={{
                                position: "absolute",
                                top: "6px",
                                right: "8px",
                                backgroundColor: "red",
                                color: "white",
                                border: "none",
                                borderRadius: "50%",
                                cursor: "pointer",
                              }}>
                              <span>X</span>
                            </button>
                          </div>
                        </div>
                      ))}
                    </div>
                  </GridLayoutItem>
                </GridLayout>
              )}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
