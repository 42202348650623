import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { FETCH_TRADES } from "../constant/Apipath";
import { PostRequestCall } from "../apicall/PostRequest";
import { IconComponent } from "./Icon";
import { Loader } from "@progress/kendo-react-indicators";
import { Label } from "@progress/kendo-react-labels";
import { AutoComplete } from "@progress/kendo-react-dropdowns";
import { Input } from "@progress/kendo-react-inputs";

const TradeSearchInput = (props) => {
  const debounceTimeout = useRef(null);
  const { loginData } = useSelector((state) => state.main);

  const [tradeOptions, setTradeOptions] = useState([]);
  const [searchLoader, setSearchLoader] = useState(false);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (!props?.disabled) {
      getList("");
    }
  }, [!props?.disabled]);

  const getList = async (seacrhTerm) => {
    try {
      let obj = {
        PageNr: 1,
        NrOfRecPerPage: 20,
        FullSearch: "",
        SearchList: [{ name: seacrhTerm }],
        SortList: [{ FieldName: "", Direction: "" }],
        IncludeRecordNr: true,
      };
      const res = await PostRequestCall(
        FETCH_TRADES,
        obj,
        loginData?.token
      );
      let resData = res?.data?.data ?? [];
      let data = resData.map((record) => ({
        id: record?.id,
        label: record?.name,
      }));
      setTradeOptions(data ?? []);
      return setSearchLoader(false);
    } catch (error) {
      setSearchLoader(false);
      console.log("Error while getting user list :: ", error);
    }
  };

  return (
    <>
      {!props?.hide && <Label className="form-heading">Trade:</Label>}
      {!props?.disabled ? (
        <div style={{ position: "relative" }}>
          <AutoComplete
            data={tradeOptions}
            value={props?.value ?? ""}
            className="field-ip"
            placeholder={props?.placeholder ?? "Search for a trade..."}
            onChange={async (event) => {
              setSearchLoader(true);
              const searchTerm = event.target.value;
              props?.setSelectedTrade({ label: event.target.value, id: 0, onTradeSelect: searchTerm ? false : true });
              if (searchTerm) {
                setOpen(true);
                if (debounceTimeout.current) {
                  clearTimeout(debounceTimeout.current);
                }
                debounceTimeout.current = setTimeout(() => {
                  getList(event.target.value);
                }, 1000);
              } else {
                setOpen(false);
              }
            }}
            textField="label"
            opened={open}
            onBlur={() => {
              setOpen(false);
            }}
            itemRender={(li, itemProps) => {
              const user = itemProps?.dataItem ?? {};
              return (
                <div
                  className="user-item"
                  onClick={() => {
                    setSearchLoader(false);
                    props?.setSelectedTrade({ ...user, onTradeSelect: true });
                    setOpen(false);
                  }}>
                  <span>{user?.label}</span>
                </div>
              );
            }}
          />
          {props?.value?.trim() === "" && (
            <div style={{ position: "absolute", top: "20%", right: "15px" }}>
              <IconComponent size={15} iconName={"Search"} color="#B5B5B5" />
            </div>
          )}
          {searchLoader && props?.value?.trim() !== "" && (
            <div
              style={{
                position: "absolute",
                top: "15%",
                right: "2%",
                cursor: "pointer",
                zIndex: 999,
                backgroundColor: "white",
              }}>
              <Loader size={"small"} style={{ color: "#083DB8" }} />
            </div>
          )}
        </div>
      ) : (
        <Input
          name="user_name"
          value={props?.value}
          className="field-ip"
          disabled={true}
          type="text"
        />
      )}
    </>
  );
};

export default TradeSearchInput;
