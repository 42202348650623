import { inboxIcon, bookIcon, commentIcon } from "@progress/kendo-svg-icons";

export const rewardMenulist = [
  {
    text: "Reward Activities",
    selected: true,
    route: "/admin/rewards/reward-activities",
    arrows: false,
  },
];
// export const reportMenulist = [
//   {
//     text: "Users",
//     selected: true,
//     route: "/reports/users/users",
//     arrows: false,
//   },
// ];

export const quizzesMenuList = [
  {
    text: "Quizzes",
    svgIcon: inboxIcon,
    selected: true,
    route: "/admin/quizzes/quizzes",
    // arrows: true,
  },
  {
    text: "Entities",
    svgIcon: bookIcon,
    selected: false,
    route: "/admin/quizzes/entities",
  },
  {
    text: "Attributes",
    svgIcon: commentIcon,
    selected: false,
    route: "/admin/quizzes/attributes",
  },
  {
    text: "Entity Attributes",
    svgIcon: commentIcon,
    selected: false,
    route: "/admin/quizzes/entity-attributes",
  },
];

export const userRolesMenuList = [
  {
    text: "User Roles",
    svgIcon: inboxIcon,
    selected: true,
    route: "/admin/user-roles/user-roles",
  },
];

export const tradeMenuList = [
  {
    text: "Trades",
    svgIcon: inboxIcon,
    selected: true,
    route: "/admin/trades/trades",
  },
  {
    text: "Alternate Trades",
    svgIcon: inboxIcon,
    selected: true,
    route: "/admin/trades/alternate-trade-name",
  },
  {
    text: "Releted Trades",
    svgIcon: inboxIcon,
    selected: true,
    route: "/admin/trades/releted-trades",
  },
];

export const compnayRoleMenuList = [
  {
    text: "Company Roles",
    svgIcon: inboxIcon,
    selected: true,
    route: "/admin/company-roles/company-roles",
  },
];

export const biRoleMenuList = [
  {
    text: "bill-info-channels",
    svgIcon: inboxIcon,
    selected: true,
    route: "/marketing/bill-info-channels",
  },
];

export const featuresMenuList = [
  {
    text: "Features",
    svgIcon: inboxIcon,
    selected: true,
    route: "/marketing/features",
  },
];
export const faqMenuList = [
  {
    text: "FAQ",
    svgIcon: inboxIcon,
    selected: true,
    route: "/marketing/faq",
  },
];

export const adsMenuList = [
  {
    text: "Ads",
    svgIcon: inboxIcon,
    selected: true,
    route: "/admin/ads/ads",
  },
];

export const postMenuList = [
  {
    text: "Posts",
    svgIcon: inboxIcon,
    selected: true,
    route: "/posts/postsmessages",
  },
];
export const userSupportMenuList = [
  {
    text: "User Support",
    svgIcon: inboxIcon,
    selected: true,
    route: "/user/users-support/users-support",
  },
];
export const userDevicesMenuList = [
  {
    text: "User Devices",
    svgIcon: inboxIcon,
    selected: true,
    route: "/user/users-devices/users-devices",
  },
];
export const ClientMenuList = [
  {
    text: "Client Auth",
    svgIcon: inboxIcon,
    selected: true,
    route: "/client/accounts/client-auth",
  },
  {
    text: "Client Ads",
    svgIcon: inboxIcon,
    selected: true,
    route: "/client/accounts/client-ads",
  },
  {
    text: "Client Devices",
    svgIcon: inboxIcon,
    selected: true,
    route: "/client/accounts/client-devices",
  },
];

export const experienceMenuList = [
  {
    text: "User Work History",
    svgIcon: inboxIcon,
    selected: true,
    route: "/user/experience/user-work-history",
  },
  {
    text: "User Experience Product",
    svgIcon: inboxIcon,
    selected: false,
    route: "/user/experience/user-experience-product",
  },
  {
    text: "User Trades Experience",
    svgIcon: inboxIcon,
    selected: false,
    route: "/user/experience/user-trades-experience",
  },
  {
    text: "User Roles Experience",
    svgIcon: inboxIcon,
    selected: false,
    route: "/user/experience/user-roles-experience",
  },
  {
    text: "Tickets",
    svgIcon: inboxIcon,
    selected: false,
    route: "/user/experience/tickets",
  },
];

export const rewardsMenuList = [
  {
    text: "User Quizzes",
    svgIcon: inboxIcon,
    selected: true,
    route: "/user/rewards/user-quizzes",
  },
  {
    text: "User Answers",
    svgIcon: inboxIcon,
    selected: false,
    route: "/user/rewards/user-answers",
  },
  {
    text: "User Reward Activities",
    svgIcon: inboxIcon,
    selected: false,
    route: "/user/rewards/user-reward-activites",
  },
  {
    text: "Items",
    svgIcon: inboxIcon,
    selected: false,
    route: "/user/rewards/items",
  },

  {
    text: "Store Items (Reward Store)",
    svgIcon: inboxIcon,
    selected: false,
    route: "/user/rewards/store-items",
  },
  // {
  //   text: "Reward Store",
  //   svgIcon: inboxIcon,
  //   selected: false,
  //   route: "/user/rewards/reward-store",
  // },
];

export const ticketsMenuList = [
  {
    text: "Tickets",
    svgIcon: inboxIcon,
    selected: true,
    route: "/user/tickets/tickets",
  },
  {
    text: "Shared With",
    svgIcon: inboxIcon,
    selected: false,
    route: "/user/tickets/shared-with",
  },
];
export const UserssMenuList = [
  {
    text: "Tickets",
    svgIcon: inboxIcon,
    selected: true,
    route: "/user/tickets/tickets",
  },
  {
    text: "Shared With",
    svgIcon: inboxIcon,
    selected: false,
    route: "/user/tickets/shared-with",
  },
];

export const chatMenuList = [
  {
    text: "Chat Groups",
    svgIcon: inboxIcon,
    selected: true,
    route: "/user/chat/chat-groups",
  },
  {
    text: "Chat Groups Users",
    svgIcon: inboxIcon,
    selected: false,
    route: "/user/chat/chat-groups-users",
  },
  {
    text: "Chat Groups Messages",
    svgIcon: inboxIcon,
    selected: false,
    route: "/user/chat/chat-groups-messages",
  },
  {
    text: "Chat Groups Users Messages",
    svgIcon: inboxIcon,
    selected: false,
    route: "/user/chat/chat-groups-user-messages",
  },
];

export const sitesMenuList = [
  {
    text: "Sites",
    svgIcon: inboxIcon,
    selected: true,
    route: "/sites/sites",
  },
  {
    text: "User Sites Projects",
    svgIcon: inboxIcon,
    selected: false,
    route: "/sites/user-sites-project",
  },
  {
    text: "Project Companies",
    svgIcon: inboxIcon,
    selected: false,
    route: "/sites/project-companies",
  },
  {
    text: "Project Products",
    svgIcon: inboxIcon,
    selected: false,
    route: "/sites/project-products",
  },
];

export const productMenuList = [
  {
    text: "Products",
    svgIcon: inboxIcon,
    selected: true,
    route: "/products/products",
  },
  {
    text: "User Products Images",
    svgIcon: inboxIcon,
    selected: false,
    route: "/products/user-product-images",
  },
];

export const companiesMenuList = [
  {
    text: "Companies",
    svgIcon: inboxIcon,
    selected: true,
    route: "/companies/companies",
  },
  {
    text: "Company Address",
    svgIcon: inboxIcon,
    selected: false,
    route: "/companies/address",
  },
];
