import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import {
  DELETE_COMPANIES_ADDRESSESS,
  FETCH_COMPANIES_ADDRESSES,
  SAVE_COMPANIES_ADDRESSESS,
} from "../../constant/Apipath";
import { PostRequestCall } from "../../apicall/PostRequest";
import { Input } from "@progress/kendo-react-inputs";
import { useSelector } from "react-redux";
import { Form } from "@progress/kendo-react-form";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { toast } from "react-toastify";
import { Loader } from "@progress/kendo-react-indicators";
import AddPageLayout from "../../addpagelayout/AddPageLayout";
import RecordStatusInput from "../../common/RecordStatusInput";
import CompnaySearchInput from "../../common/CompanySearchInput";

const AddcompanyAddress = () => {
  const { companyId, companyAddressId } = useParams();
  const { loginData } = useSelector((state) => state.main);
  const isAddForm = companyAddressId === "add-company-address";

  const [companyAddress, setCompanyAddress] = useState({
    companyId: companyId ?? 0,
    companyName: "",
    email: "",
    contactPerson: "",
    phone: "",
    type: "",
    address: "",
    city: "",
    province: "",
    countryCode: "",
    postalCode: "",
    country: "",
    recordStatusId: 1,
    recordStatus: "New",
  });

  const [showLoader, setShowLoader] = useState(false);
  const [isFieldEdited, setIsFiledEdited] = useState(false);
  const [footerInfo, setFooterInfo] = useState({});

  const [query, setQuery] = useState("");
  const autoCompleteRef = useRef(null);

  const loadScript = (url, callback) => {
    let script = document.createElement("script");
    script.type = "text/javascript";

    if (script.readyState) {
      script.onreadystatechange = function () {
        if (
          script.readyState === "loaded" ||
          script.readyState === "complete"
        ) {
          script.onreadystatechange = null;
          callback();
        }
      };
    } else {
      script.onload = () => callback();
    }

    script.src = url;
    document.getElementsByTagName("head")[0].appendChild(script);
  };

  const handleScriptLoad = (updateQuery, autoCompleteRef, companyAddress) => {
    const autoComplete = new window.google.maps.places.Autocomplete(
      autoCompleteRef.current
    );
    autoComplete.setFields(["address_components", "formatted_address"]);

    autoComplete.addListener("place_changed", () => {
      const place = autoComplete.getPlace();
      const addressComponents = place.address_components;

      let address = "";
      let city = "";
      let province = "";
      let postalCode = "";
      let country = "";

      addressComponents.forEach((component) => {
        switch (component.types[0]) {
          case "street_number":
            address += component.long_name + ", ";
            break;
          case "locality":
            city = component.long_name;
            break;
          case "administrative_area_level_1":
            province = component.long_name;
            break;
          case "postal_code":
            postalCode = component.long_name;
            break;
          case "country":
            country = component.long_name;
            break;
          default:
            break;
        }
      });

      setCompanyAddress((prevState) => ({
        ...prevState,
        address: address,
        city: city,
        province: province,
        postalCode: postalCode,
        country: country,
      }));
    });
  };

  useEffect(() => {
    loadScript(
      `https://maps.googleapis.com/maps/api/js?key=AIzaSyDFLELJHL-t3S5DkTgHRPWVBNpg8uW76Kc&libraries=places`,
      () => handleScriptLoad(setQuery, autoCompleteRef, setCompanyAddress)
    );
  }, []);

  useEffect(() => {
    if (!isAddForm) {
      getCompanyAddressDetails();
    }
  }, []);

  const getCompanyAddressDetails = async () => {
    try {
      let obj = { SearchList: [{ Id: companyAddressId }] };
      const res = await PostRequestCall(
        FETCH_COMPANIES_ADDRESSES,
        obj,
        loginData?.token
      );

      let resData = res?.data?.data?.[0] ?? {};
      setCompanyAddress((preValue) => ({
        ...preValue,
        contactPerson: resData?.contactPerson,
        email: resData?.email,
        countryCode: resData?.countryCode,
        phone: resData?.phone,
        type: resData?.type,
        address: resData?.address,
        city: resData?.city,
        province: resData?.province,
        postalCode: resData?.postalCode,
        country: resData?.country,
        companyName: resData?.companyName,
        companyId: resData?.companyId,
        recordStatus: resData?.recordStatus,
        recordStatusId: resData?.recordStatusId,
      }));
      let footerObj = {
        dateCreated: resData?.dateCreated ?? null,
        modifiedByUser: resData?.modifiedByUser ?? "",
        dateModified: resData?.dateModified ?? "",
        createdByUser: resData?.createdByUser ?? "",
      };
      setFooterInfo(footerObj);
    } catch (error) {
      console.log("Error while getting post comment details :: ", error);
    }
  };

  const onInputChange = (e, number) => {
    let inputValue = e.target.value;
    setCompanyAddress({
      ...companyAddress,
      [e.target.name]: inputValue,
    });
    setIsFiledEdited(true);
  };

  const onAddInputChange = (e) => {
    setIsFiledEdited(true);
    setCompanyAddress({
      ...companyAddress,
      [e.target.name]: e.target.value,
    });
  };

  const onNumberInputChange = (e) => {
    let inputValue = e.target.value;
    if (/^\d*$/.test(inputValue)) {
      setCompanyAddress({
        ...companyAddress,
        [e.target.name]: inputValue,
      });
      setIsFiledEdited(true);
    }
  };

  const saveCurrentData = async () => {
    if (companyAddress?.companyId === 0) {
      toast.error("Please select a vaild company", {
        position: "top-right",
      });
      return;
    }

    if (companyAddress?.city) {
      const cityRegex = /^[a-zA-Z]+(?:[\s-][a-zA-Z]+)*$/;
      if (!cityRegex.test(companyAddress?.city)) {
        alert("Please enter valid city name.");
        return false;
      }
    }

    if (companyAddress?.country) {
      const cityRegex = /^[a-zA-Z]+(?:[\s-][a-zA-Z]+)*$/;
      if (!cityRegex.test(companyAddress?.country)) {
        alert("Please enter valid country name.");
        return false;
      }
    }

    const newCompanyAddress = {
      UserId: loginData?.userId,
      ReturnRecordId: true,
      ReturnRecordError: true,
      SaveList: [
        {
          Id: isAddForm ? 0 : companyAddressId,
          CompanyId: companyAddress.companyId,
          Type: companyAddress.type,
          Address: companyAddress?.address,
          City: companyAddress?.city,
          Province: companyAddress?.province,
          PostalCode: companyAddress?.postalCode,
          Country: companyAddress?.country,
          RecordStatusId: companyAddress?.recordStatusId || 1,
          CreatedBy: loginData?.userId,
          ModifiedBy: loginData?.userId,
          ContactPerson: companyAddress.contactPerson,
          Email: companyAddress.email,
          Phone: companyAddress.phone,
        },
      ],
    };
    try {
      const res = await PostRequestCall(
        SAVE_COMPANIES_ADDRESSESS,
        newCompanyAddress,
        loginData?.token
      );

      if (res?.data?.status === false || res?.data?.status === 400) {
        let errorMessage = res?.data?.errorMessage
          ? res?.data?.errorMessage
          : res?.data?.error
          ? res?.data?.error
          : "Something went wrong. Please try again later.";
        setShowLoader(false);
        toast.error(
          errorMessage ?? "Something went wrong. Please try again later.",
          {
            position: "top-right",
          }
        );
      } else {
        toast.success(`Data ${!isAddForm ? "updated" : "added"} successfully`, {
          position: "top-right",
        });
        window.history.back();
        setShowLoader(false);
      }
    } catch (error) {
      setShowLoader(false);
      toast.error("Something went wrong. Please try again later.", {
        position: "top-right",
      });
      console.error("Error saving data :", error);
    }
  };

  const onDeleteClick = async () => {
    try {
      setShowLoader(true);
      const deleteObj = {
        ReturnRecordError: true,
        ReturnRecordId: false,
        UserId: loginData.userId,
        DeleteList: [
          {
            Id: companyAddressId,
            ModifiedBy: loginData.userId,
          },
        ],
      };
      return PostRequestCall(
        DELETE_COMPANIES_ADDRESSESS,
        deleteObj,
        loginData?.token
      )
        .then((res) => {
          setShowLoader(false);
          if (res?.data?.status) {
            toast.success("Data deleted successfully", {
              position: "top-right",
            });
            window.history.back();
          } else {
            toast.error(
              res?.data?.message ??
                "Something went wrong. Please try again later.",
              {
                position: "top-right",
              }
            );
          }
        })
        .catch((error) => {
          setShowLoader(false);
          toast.error("Something went wrong. Please try again later.", {
            position: "top-right",
          });
          console.log("Error while deleting a record :: ", error);
        });
    } catch (error) {
      setShowLoader(false);
      toast.error("Something went wrong. Please try again later.", {
        position: "top-right",
      });
      console.log("Error while deleting a record :: ", error);
    }
  };

  if (showLoader) {
    return (
      <Loader
        type="converging-spinner"
        className="kendo-spinner"
        style={{
          display: "flex",
          justifyContent: "center",
          minHeight: "500px",
          alignItems: "center",
        }}
      />
    );
  }

  return (
    <>
      <div className="add_form_height_100">
        <AddPageLayout
          dataId={companyAddressId}
          addForm={isAddForm}
          showFooter={!isAddForm}
          footerInfo={footerInfo}
          isFieldEdited={isFieldEdited}
          recordText={"Company Address ID"}
          saveCurrentData={saveCurrentData}
          onDeleteClick={onDeleteClick}>
          <div style={{ height: "100%" }}>
            <Form
              render={() => (
                <GridLayout
                  rows={[
                    {
                      height: 70,
                    },
                  ]}
                  cols={[
                    {
                      width: 375,
                    },
                    {
                      width: 375,
                    },
                    {
                      width: 375,
                    },
                  ]}
                  gap={{
                    rows: 30,
                    cols: 35,
                  }}
                  style={{ padding: "16px" }}>
                  <GridLayoutItem col={1} row={1} className="form-inner">
                    <CompnaySearchInput
                      value={companyAddress?.companyName}
                      setSelectedCompany={({ label, id }) => {
                        setIsFiledEdited(true);
                        setCompanyAddress((preValue) => ({
                          ...preValue,
                          companyName: label,
                          companyId: id,
                        }));
                      }}
                      disabled
                      companyId={companyId}
                    />
                  </GridLayoutItem>

                  <GridLayoutItem col={3} row={1} className="form-inner">
                    <label className="form-heading">Contact Person:</label>
                    <Input
                      type="text"
                      id="contactPerson"
                      name="contactPerson"
                      value={companyAddress?.contactPerson}
                      className="field-ip"
                      onChange={(e) => onInputChange(e)}
                    />
                  </GridLayoutItem>

                  <GridLayoutItem col={2} row={1} className="form-inner">
                    <label className="form-heading">Email:</label>
                    <Input
                      type="email"
                      id="email"
                      name="email"
                      value={companyAddress?.email}
                      className="field-ip"
                      onChange={(e) => onInputChange(e)}
                    />
                  </GridLayoutItem>

                  <GridLayoutItem col={1} row={2} className="form-inner">
                    <label className="form-heading">Phone:</label>
                    <Input
                      id="phone"
                      name="phone"
                      value={companyAddress?.phone}
                      className="field-ip"
                      onChange={(e) => onNumberInputChange(e)}
                    />
                  </GridLayoutItem>

                  <GridLayoutItem col={2} row={2} className="form-inner">
                    <label className="form-heading">Address Type:</label>
                    <Input
                      type="text"
                      id="type"
                      name="type"
                      value={companyAddress?.type}
                      className="field-ip"
                      onChange={(e) => onInputChange(e)}
                    />
                  </GridLayoutItem>

                  <GridLayoutItem col={3} row={2} className="form-inner">
                    <label className="form-heading">Search Address:</label>
                    <input
                      type="text"
                      ref={autoCompleteRef}
                      placeholder="Enter address"
                      className="field-ip"
                      onChange={(e) => onAddInputChange(e)}
                      style={{
                        padding: "10px",
                        width: "95%",
                        height: "7px",
                        borderRadius: "4px",
                        outline: "none",
                      }}
                    />
                  </GridLayoutItem>

                  <GridLayoutItem col={1} row={3} className="form-inner">
                    <label className="form-heading"> Address:</label>
                    <Input
                      type="text"
                      id="address"
                      name="address"
                      value={companyAddress?.address}
                      className="field-ip"
                      onChange={(e) => onInputChange(e)}
                    />
                  </GridLayoutItem>

                  <GridLayoutItem col={2} row={3} className="form-inner">
                    <label className="form-heading">City:</label>
                    <Input
                      type="text"
                      id="city"
                      name="city"
                      value={companyAddress?.city}
                      className="field-ip"
                      onChange={(e) => onInputChange(e)}
                    />
                  </GridLayoutItem>

                  <GridLayoutItem col={3} row={3} className="form-inner">
                    <label className="form-heading">Province:</label>
                    <Input
                      type="text"
                      id="province"
                      name="province"
                      value={companyAddress?.province}
                      className="field-ip"
                      onChange={(e) => onInputChange(e)}
                    />
                  </GridLayoutItem>

                  <GridLayoutItem col={1} row={4} className="form-inner">
                    <label className="form-heading"> Postal Code:</label>
                    <Input
                      type="text"
                      id="postalCode"
                      name="postalCode"
                      value={companyAddress?.postalCode}
                      className="field-ip"
                      onChange={(e) => onInputChange(e)}
                    />
                  </GridLayoutItem>

                  <GridLayoutItem col={2} row={4} className="form-inner">
                    <label className="form-heading"> Country:</label>
                    <Input
                      type="text"
                      id="country"
                      name="country"
                      value={companyAddress?.country}
                      className="field-ip"
                      onChange={(e) => onInputChange(e)}
                    />
                  </GridLayoutItem>

                  {!isAddForm && (
                    <GridLayoutItem col={3} row={4} className="form-inner">
                      <RecordStatusInput
                        recordStatusId={companyAddress?.recordStatusId}
                        recordStatus={companyAddress?.recordStatus}
                        onChange={({ recordStatus, recordStatusId }) => {
                          setIsFiledEdited(true);
                          setCompanyAddress((preValue) => ({
                            ...preValue,
                            recordStatus,
                            recordStatusId,
                          }));
                        }}
                      />
                    </GridLayoutItem>
                  )}
                </GridLayout>
              )}
            />
          </div>
        </AddPageLayout>
      </div>
    </>
  );
};

export default AddcompanyAddress;
