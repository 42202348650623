import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  DELETE_ADS_CLIENTS,
  FETCH_ADS_CLIENTS,
  FETCH_AUTH_ADS_CLIENTS,
  SAVE_ADS_CLIENTS,
} from "../../constant/Apipath";
import { PostRequestCall } from "../../apicall/PostRequest";
import { Input, Switch, TextArea } from "@progress/kendo-react-inputs";
import { useSelector } from "react-redux";
import { Form } from "@progress/kendo-react-form";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { Error, Label } from "@progress/kendo-react-labels";
import { IconComponent } from "../../common/Icon";
import { toast } from "react-toastify";
import { Loader } from "@progress/kendo-react-indicators";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import moment from "moment";
import { AutoComplete } from "@progress/kendo-react-dropdowns";
import AddPageLayout from "../../addpagelayout/AddPageLayout";

const AddClientAds = () => {
  const { dataId, clientSubMenu, clientId } = useParams();
  const [showLoader, setShowLoader] = useState(false);
  const { loginData } = useSelector((state) => state.main);
  const [footerInfo, setFooterInfo] = useState({});
  const [isFieldEdited, setIsFiledEdited] = useState(false);
  const [value, setValue] = useState("");
  const [showClientList, setShowClientList] = useState(false);
  const [clientOptions, setClientOptions] = useState([]);
  const isAddForm = dataId === "add-client-ads";
  const [adsData, setAdsData] = useState({
    title: "",
    description: "",
    redirectUrl: "",
    webRedirectUrl: "",
    clientId: clientId ?? 0,
    fileName: "",
    isActive: false,
    alreadyFileName: "",
    clientSearchTerm: "",
  });

  useEffect(() => {
    if (!isAddForm) {
      loadClientAds();
    }
    if (clientSubMenu && clientId) {
      getCurrentClientDetails(clientId);
    }
  }, []);

  const loadClientAds = async () => {
    if (!isAddForm) {
      var obj = JSON.stringify({
        PageNr: 1,
        NrOfRecPerPage: 20,
        FullSearch: "",
        SearchList: [
          {
            Id: dataId,
          },
        ],
        SortList: [
          {
            FieldName: "Id",
            Direction: "ASC",
          },
        ],
        IncludeRecordNr: true,
      });

      try {
        const result = await PostRequestCall(
          FETCH_ADS_CLIENTS,
          obj,
          loginData?.token
        );
        if (result?.data?.data && result.data.data.length > 0) {
          let getClientadsData = result.data.data?.[0];

          setAdsData({
            ...adsData,
            ...getClientadsData,
            title: getClientadsData?.title,
            description: getClientadsData?.description,
            redirectUrl: getClientadsData?.redirectUrl,
            startCampaignDate: getClientadsData?.startCampaignDate
              ? new Date(getClientadsData?.startCampaignDate)
              : null,
            endCampaignDate: getClientadsData?.endCampaignDate
              ? new Date(getClientadsData?.endCampaignDate)
              : null,
            isActive: getClientadsData?.active,
            clientId: getClientadsData?.clientId,
            fileName:
              getClientadsData?.fileName === "" ||
              getClientadsData?.fileName === null
                ? null
                : getClientadsData?.fileName,
            alreadyFileName:
              getClientadsData?.fileName === "" ||
              getClientadsData?.fileName === null
                ? null
                : getClientadsData?.fileName,
          });
          let footerObj = {
            dateCreated: getClientadsData?.dateCreated ?? null,
            modifiedByUser: getClientadsData?.modifiedByUser ?? "",
            dateModified: getClientadsData?.dateModified ?? "",
            createdByUser: getClientadsData?.createdByUser ?? "",
          };
          setFooterInfo(footerObj);
          if (getClientadsData?.clientId) {
            getCurrentClientDetails(getClientadsData?.clientId);
          }
        } else {
          console.log("No data found for the given ID");
        }
      } catch (error) {
        console.log("Error while loading client ads data :: ", error);
      }
    }
  };

  const getCurrentClientDetails = async (clientId) => {
    try {
      const obj = JSON.stringify({
        PageNr: 1,
        NrOfRecPerPage: 0,
        FullSearch: "",
        UserId: loginData.userId,
        SearchList: [{ Id: clientId }],
        IncludeRecordNr: true,
      });
      const res = await PostRequestCall(
        FETCH_AUTH_ADS_CLIENTS,
        obj,
        loginData?.token
      );
      let clientDetails = res?.data?.data?.[0] ?? {};
      setValue(clientDetails?.firstName + " " + clientDetails?.lastName);
    } catch (error) {
      console.log("Error while getting client data :: ", error);
    }
  };

  const getClientList = async (onFocus, searchTerm) => {
    try {
      const obj = JSON.stringify({
        PageNr: 1,
        NrOfRecPerPage: onFocus ? 20 : 0,
        FullSearch: "",
        // FullSearch: onFocus ? "" : searchTerm,
        UserId: loginData.userId,
        // SearchList: [],
        SearchList: onFocus ? [] : [{ firstName: searchTerm }],
        IncludeRecordNr: true,
      });
      const getResult = await PostRequestCall(
        FETCH_AUTH_ADS_CLIENTS,
        obj,
        loginData?.token
      );
      if (getResult?.data?.data?.length > 0) {
        const data = getResult?.data?.data.map((user) => ({
          value: user.id,
          label: `${user?.firstName} ${user?.lastName}`,
        }));
        setClientOptions(data);
        setShowClientList(true);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const onInputChange = (e) => {
    setIsFiledEdited(true);
    setAdsData({
      ...adsData,
      [e.target.name]: e.target.value,
    });
  };

  const onProfileChange = (e) => {
    if (e?.target?.files?.length > 0) {
      setAdsData({
        ...adsData,
        fileName: e.target.files[0],
      });
    }
    e.target.value = "";
  };

  const onSelectProfile = () => {
    setIsFiledEdited(true);
    document.getElementById("user-profile").click();
  };

  const onRemoveImage = () => {
    setIsFiledEdited(true);
    setAdsData({
      ...adsData,
      fileName: null,
    });
  };

  const saveCurrentData = async () => {
    if (adsData?.clientId === 0) {
      return toast.error("Please select a client", {
        position: "top-right",
      });
    }

    const getRequest = new FormData();
    setShowLoader(true);
    if (isAddForm) {
      getRequest.append("Id", 0);
    } else {
      getRequest.append("Id", dataId);
    }

    getRequest.append("Title", adsData.title);
    getRequest.append("Description", adsData?.description);
    getRequest.append("RedirectUrl", adsData?.redirectUrl);
    if (adsData?.startCampaignDate) {
      getRequest.append(
        "startCampaignDate",
        adsData?.startCampaignDate
          ? moment(adsData?.startCampaignDate).format("YYYY-MM-DD")
          : ""
      );
    }
    if (adsData?.endCampaignDate) {
      getRequest.append(
        "endCampaignDate",
        adsData?.endCampaignDate
          ? moment(adsData?.endCampaignDate).format("YYYY-MM-DD")
          : ""
      );
    }

    getRequest.append("ClientId", adsData?.clientId);
    getRequest.append("Active", adsData?.isActive);

    if (adsData?.fileName) {
      if (adsData?.fileName !== adsData?.alreadyFileName) {
        getRequest.append("files", adsData?.fileName);
      }
    } else {
      getRequest.append("ForceRemovePicture", true);
    }

    getRequest.append("ModifiedBy", loginData?.userId);
    let getResponse = await PostRequestCall(
      SAVE_ADS_CLIENTS,
      getRequest,
      loginData?.token,
      "formData"
    );

    if (getResponse?.data?.status === false) {
      setShowLoader(false);
      toast.error(
        getResponse?.data?.message ??
          "Something went wrong. Please try again later.",
        {
          position: "top-right",
        }
      );
    } else {
      setShowLoader(false);
      toast.success(`Data ${!isAddForm ? "updated" : "added"} successfully`, {
        position: "top-right",
      });
      window.history.back();
    }
  };

  const isCheck = (pictureUrl) => {
    if (Object.keys(pictureUrl)?.length === 0) {
      return URL.createObjectURL(pictureUrl);
    } else {
      return pictureUrl;
    }
  };

  const onDeleteClick = async () => {
    try {
      setShowLoader(true);

      const deleteObj = {
        ReturnRecordError: true,
        softDelete: false,
        DeleteList: [
          {
            Id: Number(dataId),
          },
        ],
      };

      return PostRequestCall(DELETE_ADS_CLIENTS, deleteObj, loginData?.token)
        .then((res) => {
          setShowLoader(false);
          if (res?.status === 201) {
            toast.success(`Data delete successfully`, {
              position: "top-right",
            });

            window.history.back();
          } else {
            toast.error(
              res?.data?.message ??
                "Something went wrong. Please try again later.",
              {
                position: "top-right",
              }
            );
          }
        })
        .catch((error) => {
          setShowLoader(false);
          toast.error("Something went wrong. Please try again later.", {
            position: "top-right",
          });
          console.log("Error while deleting a record :: ", error);
        });
    } catch (error) {
      setShowLoader(false);
      toast.error("Something went wrong. Please try again later.", {
        position: "top-right",
      });
      console.log("Error while deleting a record :: ", error);
    }
  };

  const onStartCompaignchange = (e) => {
    setIsFiledEdited(true);
    setAdsData({
      ...adsData,
      startCampaignDate: e.value,
    });
  };

  const onEndCompaignchange = (e) => {
    setIsFiledEdited(true);
    setAdsData({
      ...adsData,
      endCampaignDate: e.value,
    });
  };

  const onClientRender = (li, itemProps) => {
    const selectedClient = itemProps?.dataItem;
    return (
      <div
        className="user-item"
        onClick={() => {
          setShowClientList(false);
          setValue(selectedClient?.label);
          setAdsData((prevData) => ({
            ...prevData,
            clientId: selectedClient?.value ?? 0,
          }));
        }}>
        <span>{selectedClient?.label}</span>
      </div>
    );
  };

  if (showLoader) {
    return (
      <Loader
        type="converging-spinner"
        className="kendo-spinner"
        style={{
          display: "flex",
          justifyContent: "center",
          minHeight: "500px",
          alignItems: "center",
        }}
      />
    );
  }

  return (
    <AddPageLayout
      dataId={dataId}
      addForm={isAddForm}
      showFooter={!isAddForm}
      footerInfo={footerInfo}
      isFieldEdited={isFieldEdited}
      recordText={"Client Ads ID"}
      saveCurrentData={saveCurrentData}
      onDeleteClick={onDeleteClick}>
      <div style={{ height: "100%" }}>
        <Form
          render={(formRenderProps) => (
            <GridLayout
              rows={[
                { height: 70 },
                { height: 70 },
                { height: 70 },
                { height: 130 },
                { height: 30 },
              ]}
              cols={[{ width: 375 }, { width: 375 }, { width: 375 }]}
              gap={{ rows: 30, cols: 35 }}
              style={{ padding: "16px" }}>
              <GridLayoutItem col={1} row={1} className="form-inner">
                <Label className="form-heading"> Client :</Label>
                <AutoComplete
                  data={clientOptions}
                  onFocus={() => getClientList(true, adsData?.clientSearchTerm)}
                  onClose={() => setShowClientList(false)}
                  onBlur={() => setShowClientList(false)}
                  opened={showClientList}
                  value={value}
                  className="field-ip"
                  itemRender={onClientRender}
                  onChange={(e) => {
                    setValue(e?.target?.value);
                    getClientList(false, e?.target?.value);
                  }}
                  textField="label"
                  placeholder="Search for client..."
                  disabled={!isAddForm || clientSubMenu}
                />
                <Error></Error>
              </GridLayoutItem>
              <GridLayoutItem col={2} row={1} className="form-inner">
                <Label className="form-heading"> Title :</Label>
                <Input
                  name="title"
                  value={adsData?.title}
                  className="field-ip"
                  onChange={onInputChange}
                />
                <Error></Error>
              </GridLayoutItem>
              <GridLayoutItem col={3} row={1} className="form-inner">
                <Label className="form-heading"> Redirect Url :</Label>
                <Input
                  name="redirectUrl"
                  value={adsData?.redirectUrl}
                  className="field-ip"
                  onChange={onInputChange}
                />
                <Error></Error>
              </GridLayoutItem>
              <GridLayoutItem col={1} row={2} className="form-inner">
                <Label className="form-heading">Start Campaign Date :</Label>
                <DatePicker
                  name="startCampaignDate"
                  value={adsData?.startCampaignDate}
                  onChange={onStartCompaignchange}
                  format="dd/MM/yyyy"
                  className="dropdown-list"
                />
              </GridLayoutItem>
              <GridLayoutItem col={2} row={2} className="form-inner">
                <Label className="form-heading">End Campaign Date :</Label>
                <DatePicker
                  name="endCampaignDate"
                  value={adsData?.endCampaignDate}
                  onChange={onEndCompaignchange}
                  format="dd/MM/yyyy"
                  className="dropdown-list"
                />
              </GridLayoutItem>
              <GridLayoutItem col={3} row={2} className="form-inner">
                <label className="form-heading">Active :</label>
                <div>
                  <Switch
                    size="small"
                    checked={adsData?.isActive}
                    onChange={(e) => {
                      setIsFiledEdited(true);
                      setAdsData({
                        ...adsData,
                        isActive: e.value,
                      });
                    }}
                  />
                </div>
              </GridLayoutItem>
              <GridLayoutItem col={1} row={3} className="form-inner">
                <label className="form-heading">Description :</label>
                <div style={{ display: "flex", gap: "2px" }}>
                  <TextArea
                    type="text"
                    id="description"
                    name="description"
                    value={adsData?.description}
                    className="field-ip"
                    onChange={(e) => onInputChange(e)}
                    rows={2}
                  />
                </div>
              </GridLayoutItem>

              <GridLayoutItem col={2} row={3} className="form-inner">
                <Label className="form-heading"> Add Ads Image :</Label>
                <div className="k-d-flex" style={{ gap: "15px" }}>
                  <input
                    type="file"
                    hidden
                    accept=".png,.jpg"
                    id="user-profile"
                    style={{ display: "none" }}
                    onChange={(e) => {
                      setIsFiledEdited(true);
                      onProfileChange(e);
                    }}
                  />
                  {adsData?.fileName ? (
                    <div className="k-d-flex">
                      <div style={{ position: "relative" }}>
                        <img
                          className="user-img"
                          src={isCheck(adsData?.fileName)}
                          alt="Ad"
                        />
                        <button
                          onClick={onRemoveImage}
                          style={{
                            position: "absolute",
                            top: "6px",
                            right: "8px",
                            backgroundColor: "red",
                            color: "white",
                            border: "none",
                            borderRadius: "50%",
                            cursor: "pointer",
                          }}>
                          <span>X</span>
                        </button>
                      </div>
                    </div>
                  ) : (
                    <>
                      <GridLayoutItem col={2} row={1} className="form-inner">
                        <div
                          style={{
                            position: "relative",
                            cursor: "pointer",
                            border: "0.5px solid #d3d3d3",
                            padding: "5px",
                            display: "flex",
                            justifyContent: "center",
                            borderRadius: "4px",
                            gap: "10px",
                          }}
                          onClick={onSelectProfile}>
                          <IconComponent iconName={"Image"} size={20} />
                          <span className="add-file-text">Choose image</span>
                        </div>
                      </GridLayoutItem>
                    </>
                  )}
                </div>
              </GridLayoutItem>
            </GridLayout>
          )}
        />
      </div>
    </AddPageLayout>
  );
};

export default AddClientAds;
