import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { FETCH_POSTS } from "../constant/Apipath";
import { PostRequestCall } from "../apicall/PostRequest";
import { IconComponent } from "./Icon";
import { Loader } from "@progress/kendo-react-indicators";
import { Label } from "@progress/kendo-react-labels";
import { AutoComplete } from "@progress/kendo-react-dropdowns";
import { Input } from "@progress/kendo-react-inputs";

const PostIdSearchInput = (props) => {
  const debounceTimeout = useRef(null);
  const { loginData } = useSelector((state) => state.main);

  const [options, setOptions] = useState([]);
  const [searchLoader, setSearchLoader] = useState(false);
  const [open, setOpen] = useState(false);
  const [postDetails, setPostDetails] = useState({});

  useEffect(() => {
    getList("");
  }, []);

  const getList = async (seacrhTerm) => {
    try {
      let obj = {
        PageNr: 1,
        NrOfRecPerPage: 20,
        FullSearch: "",
        SearchList: [
          props?.postId && props?.disabled
            ? { id: props?.postId }
            : { id: Number(seacrhTerm) },
        ],
        SortList: [{ FieldName: "", Direction: "" }],
        IncludeRecordNr: true,
      };
      const res = await PostRequestCall(FETCH_POSTS, obj, loginData?.token);
      let resData = res?.data?.data ?? [];
      if (props?.postId && props?.disabled) {
        props?.setAddId(resData?.[0]?.appId ?? 0);
        return setPostDetails(resData?.[0] ?? {});
      } else {
        let data = resData.map((record) => ({
          id: record?.id,
          label: record?.id,
          appId: record?.appId,
        }));
        setSearchLoader(false);
        setOptions(data ?? []);
      }
    } catch (error) {
      setSearchLoader(false);
      console.log("Error while getting user list :: ", error);
    }
  };

  return (
    <>
      <Label className="form-heading">Post ID:</Label>
      {!props?.disabled ? (
        <div style={{ position: "relative" }}>
          <AutoComplete
            data={options}
            value={props?.value}
            className="field-ip"
            placeholder="Search for a post id..."
            onChange={async (event) => {
              const searchTerm = event.target.value;
              if (/^\d*$/.test(searchTerm)) {
                setSearchLoader(true);
                props?.setSelectedPostId({
                  label: event.target.value,
                  id: "",
                  appId: "",
                });
                if (searchTerm) {
                  setOpen(true);
                  if (debounceTimeout.current) {
                    clearTimeout(debounceTimeout.current);
                  }
                  debounceTimeout.current = setTimeout(() => {
                    getList(event.target.value);
                  }, 1000);
                } else {
                  setOpen(false);
                }
              }
            }}
            textField="label"
            opened={open}
            onBlur={() => {
              setOpen(false);
            }}
            itemRender={(li, itemProps) => {
              const data = itemProps?.dataItem;
              return (
                <div
                  className="user-item"
                  onClick={() => {
                    setSearchLoader(false);
                    props?.setSelectedPostId(data);
                    setOpen(false);
                  }}>
                  <span>{data?.label}</span>
                </div>
              );
            }}
          />
          {props?.value?.toString()?.trim() === "" && (
            <div style={{ position: "absolute", top: "20%", right: "15px" }}>
              <IconComponent size={15} iconName={"Search"} color="#B5B5B5" />
            </div>
          )}
          {searchLoader && props?.value?.toString()?.trim() !== "" && (
            <div
              style={{
                position: "absolute",
                top: "15%",
                right: "2%",
                cursor: "pointer",
                zIndex: 999,
                backgroundColor: "white",
              }}>
              <Loader size={"small"} style={{ color: "#083DB8" }} />
            </div>
          )}
        </div>
      ) : (
        <Input
          name="post_name"
          value={
            props?.postId && props?.disabled ? postDetails?.id : props?.value
          }
          className="field-ip"
          disabled={true}
          type="text"
        />
      )}
    </>
  );
};

export default PostIdSearchInput;
