import React, { useEffect, useState, useRef } from "react";
import { Form } from "@progress/kendo-react-form";
import { Input, TextArea } from "@progress/kendo-react-inputs";
import { Loader } from "@progress/kendo-react-indicators";
import { toast } from "react-toastify";
import { PostRequestCall } from "../apicall/PostRequest";
import { FETCH_USERS, SEND_DEVICE_NOTIFICATION } from "../constant/Apipath";
import { useSelector } from "react-redux";
import {
  ListBox,
  ListBoxToolbar,
  processListBoxData,
  processListBoxDragAndDrop,
} from "@progress/kendo-react-listbox";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { Label } from "@progress/kendo-react-labels";
import KendoButton from "../common/KendoButton";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import TradeSearchInput from "../common/TradeSearchInput";
import ProjectCompanyRoleSearchInput from "../common/ProjectCompanyRoleSearchInput";
import { Autocomplete as GooglePlacesAutocomplete } from "@react-google-maps/api";
import UserSiteProjectSearchInput from "../common/UserSiteProjectSearchInput";

const SELECTED_FIELD = "selected";

const SendPushNotification = () => {
  const { loginData } = useSelector((state) => state.main);
  const [userOption, setUserOptions] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [notification, setNotification] = useState({ body: "", title: "" });
  const [showLoader, setShowLoader] = useState(false);
  const [draggedItem, setDraggedItem] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchLoader, setSearchLoader] = useState(true);
  const [selectAllUser, setSelectAllUser] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState({
    text: "Select filter",
    value: "",
  });
  const [autoComplete, setAutoComplete] = useState(null);

  const filterOptions = [
    { text: "Select filter", value: "" },
    { text: "User Name", value: "name" },
    { text: "City", value: "city" },
    { text: "Province/State", value: "province" },
    { text: "Country", value: "country" },
    { text: "Trade", value: "tradeName" },
    { text: "Project", value: "projectName" },
  ];

  const debounceTimeout = useRef(null);

  useEffect(() => {
    getUserdata("");
  }, []);

  const removeAlreadySelectedUser = (list, selectedUsers) => {
    const idsToRemove = selectedUsers.map((obj) => obj.id);
    const updatedUserList = list.filter((obj) => !idsToRemove.includes(obj.id));
    return updatedUserList;
  };

  const getUserdata = async (searchTerm) => {
    try {
      let searchListValue =
        selectedFilter.value !== "" ? selectedFilter.value : "name";
      setSearchLoader(true);
      const obj = JSON.stringify({
        PageNr: 1,
        FullSearch: searchTerm ?? "",
        UserId: loginData.userId,
        SearchList: [
          { active: true },
          { [searchListValue]: searchTerm?.trim() },
        ],
        SortList: [
          { fieldName: "firstName", direction: "asc" },
          { fieldName: "lastName", direction: "asc" },
        ],
        IncludeRecordNr: true,
      });

      const getResult = await PostRequestCall(
        FETCH_USERS,
        obj,
        loginData?.token
      );

      let data = getResult?.data?.data ?? [];
      const list = data
        .map((item) => ({
          id: item.id,
          name: item.name,
          selected: false,
        }))
        .filter((item) => item?.name?.trim() !== "");

      const updatedList = removeAlreadySelectedUser(list, selectedUsers);
      setUserOptions(updatedList ?? []);
      setSearchLoader(false);
    } catch (error) {
      setSearchLoader(false);
      console.log("error", error);
    }
  };

  const handleItemClick = (event, data, connectedData) => {
    const itemId = event.dataItem.id;
    setSelectAllUser(false);

    setUserOptions((prevOptions) =>
      prevOptions.map((item) => {
        if (item.id === itemId) {
          item[SELECTED_FIELD] = !item[SELECTED_FIELD];
        } else if (!event.nativeEvent.ctrlKey) {
          item[SELECTED_FIELD] = false;
        }
        return item;
      })
    );

    if (data === "selectedUsers") {
      setSelectedUsers((prevOptions) =>
        prevOptions.map((item) => {
          if (item.id === itemId) {
            item[SELECTED_FIELD] = !item[SELECTED_FIELD];
          } else if (!event.nativeEvent.ctrlKey) {
            item[SELECTED_FIELD] = false;
          }
          return item;
        })
      );
    }
    // else {
    //   setSelectedUsers((prevSelected) =>
    //     prevSelected.map((item) => {
    //       item[SELECTED_FIELD] = !item[SELECTED_FIELD];
    //       return item;
    //     })
    //   );
    // }
  };

  const handleToolBarClick = (e) => {
    const toolName = e.toolName || "";
    const result = processListBoxData(
      userOption,
      selectedUsers,
      toolName,
      SELECTED_FIELD
    );

    setUserOptions(result.listBoxOneData);
    setSelectAllUser(false);
    let updatedSelectedUser = result?.listBoxTwoData?.map((item) => ({
      ...item,
      selected: false,
    }));
    setSelectedUsers(updatedSelectedUser ?? []);
  };

  const handleDragStart = (e) => {
    setDraggedItem(e.dataItem);
  };

  const handleDrop = (e) => {
    const result = processListBoxDragAndDrop(
      userOption,
      selectedUsers,
      draggedItem,
      e.dataItem,
      "id"
    );

    setUserOptions(result.listBoxOneData);
    setSelectedUsers(result.listBoxTwoData);
  };

  const sendNotification = async () => {
    setShowLoader(true);
    // const apiUrl = `${SEND_DEVICE_NOTIFICATION}`;

    const getUsers = selectedUsers.map((item) => item.id).join(",");
    const getObject = {
      title: notification?.title,
      Body: notification?.body,
      NotifyUsers: getUsers,
      // ActionUrl: "mybuilderid://screenName=DashboardNotifications",
      SenderId: loginData?.userId,
      CategoryId: 1,
      SaveInHistory: true,
    };

    try {
      const response = await PostRequestCall(
        SEND_DEVICE_NOTIFICATION,
        getObject,
        loginData?.token
      );
      setShowLoader(false);
      if (response?.status === 200) {
        toast.success(`Notification sent successfully`, {
          position: "top-right",
        });
        setNotification({ body: "", title: "" });
        setUserOptions([]);
        setSelectedUsers([]);
        setSelectAllUser(false);
        setSearchTerm("");
      }
    } catch (error) {
      console.log("Error sending notification:", error);
      setShowLoader(false);
    }
  };

  const onLoadAuto = (newInstance) => {
    // Access the suggestion list container and add a custom class
    setAutoComplete(newInstance);
  };

  const handlePlaceChanged = (place1) => {
    let place = autoComplete.getPlace();
    if (place) {
      const { geometry, address_components } = place;

      const countryComponent = address_components?.find((component) =>
        component.types.includes("country")
      );
      const cityComponent = address_components?.find((component) =>
        component.types.includes("locality")
      );
      const provinceComponent = address_components?.find((component) =>
        component.types.includes("administrative_area_level_1")
      );
      const country = countryComponent ? countryComponent?.long_name : "";
      const city = cityComponent ? cityComponent?.long_name : "";
      const province = provinceComponent ? provinceComponent?.short_name : "";

      let selectedFilterValue = selectedFilter?.value ?? "";
      if (selectedFilterValue === "city") {
        getUserdata(city);
        return setSearchTerm(city);
      }
      if (selectedFilterValue === "province") {
        getUserdata(province);
        return setSearchTerm(province);
      }
      if (selectedFilterValue === "country") {
        getUserdata(country);
        return setSearchTerm(country);
      }
      return setSearchTerm("");
    }
  };

  const renderSearchInput = () => {
    const placeholders = {
      name: "Search by user name",
      city: "Search by city",
      province: "Search by province",
      country: "Search by country",
      tradeName: "Search  a trade",
      projectName: "Search by project",
    };
    let selectedFilterValue = selectedFilter?.value ?? "";

    const placeholder =
      placeholders[selectedFilterValue] || "Search by name, city or trade";

    if (selectedFilter.value === "tradeName") {
      return (
        <TradeSearchInput
          value={searchTerm}
          setSelectedTrade={({ id, label, onTradeSelect }) => {
            setSearchTerm(label);
            if (onTradeSelect) {
              getUserdata(label);
              // if (debounceTimeout.current) {
              //   clearTimeout(debounceTimeout.current);
              // }
              // debounceTimeout.current = setTimeout(() => {
              //   getUserdata(label);
              // }, 1000);
            }
          }}
          disabled={false}
          hide={true}
        />
      );
    } else if (selectedFilterValue === "projectName") {
      return (
        <UserSiteProjectSearchInput
          value={searchTerm}
          setSelectedProject={({ id, label, onProjectSelect }) => {
            setSearchTerm(label);
            if (onProjectSelect) {
              getUserdata(label);
              // if (debounceTimeout.current) {
              //   clearTimeout(debounceTimeout.current);
              // }
              // debounceTimeout.current = setTimeout(() => {
              //   getUserdata(label);
              // }, 1000);
            }
          }}
          disabled={false}
          hide={true}
        />
      );
    } else if (
      selectedFilterValue === "city" ||
      selectedFilterValue === "province" ||
      selectedFilterValue === "country"
    ) {
      return (
        <div>
          <GooglePlacesAutocomplete
            onLoad={(newInstance) => onLoadAuto(newInstance)} // Sets instance of Autocomplete to be referenced
            onPlaceChanged={(place) => handlePlaceChanged(place)}
            options={{
              componentRestrictions: { country: ["US", "CA", "GB"] }, // Optional: Restrict to certain countries
            }}>
            <Input
              name="ticketId"
              value={searchTerm}
              className="field-ip"
              onChange={(e) => {
                setSearchTerm(e.target.value);
              }}
            />
          </GooglePlacesAutocomplete>
        </div>
      );
    } else {
      return (
        <Input
          type="text"
          name="search"
          id="search"
          style={{ marginBottom: "2px" }}
          value={searchTerm}
          onChange={(e) => {
            setSearchTerm(e.target.value);
            if (debounceTimeout.current) {
              clearTimeout(debounceTimeout.current);
            }
            debounceTimeout.current = setTimeout(() => {
              getUserdata(e.target.value);
            }, 1000);
            setSearchLoader(true);
          }}
          className="field-ip"
          placeholder={placeholder}
        />
      );
    }
  };

  if (showLoader) {
    return (
      <Loader
        type="converging-spinner"
        className="kendo-spinner"
        style={{
          display: "flex",
          justifyContent: "center",
          minHeight: "500px",
          alignItems: "center",
        }}
      />
    );
  }

  return (
    <div className="add_page_main_container">
      <div className="add_page_container">
        <div className="add_form_height_95">
          <div
            className="k-d-flex k-justify-content-end k-p-2"
            style={{ gap: "10px" }}>
            <KendoButton
              iconClass=""
              size={16}
              className="action-btn"
              buttonName="SEND"
              onClick={sendNotification}
            />
          </div>
          <div
            style={{
              borderBottom: "1px solid #f5f5f5",
              width: "100%",
            }}></div>
          <div style={{ width: "100%" }}>
            <Form
              render={() => (
                <GridLayout
                  rows={[
                    { height: 70 },
                    { height: 70 },
                    { height: 130 },
                    { height: 200 },
                  ]}
                  cols={[{ width: 500 }, { width: 500 }, { width: 375 }]}
                  gap={{ rows: 50, cols: 55 }}
                  style={{ padding: "16px" }}>
                  <GridLayoutItem col={1} row={1} className="form-inner">
                    <Label className="form-heading">Title:</Label>
                    <Input
                      type="text"
                      id="title"
                      name="title"
                      value={notification?.title}
                      className="field-ip"
                      onChange={(e) =>
                        setNotification({
                          ...notification,
                          title: e.target.value,
                        })
                      }
                    />
                  </GridLayoutItem>
                  <GridLayoutItem col={2} row={1} className="form-inner">
                    <Label className="form-heading">Body:</Label>
                    <TextArea
                      id="body"
                      name="body"
                      value={notification?.body}
                      className="field-ip"
                      onChange={(e) =>
                        setNotification({
                          ...notification,
                          body: e.target.value,
                        })
                      }
                      rows={4}
                      maxLength={150}
                      style={{ width: "100%", height: "80px" }}
                    />
                    <div style={{ fontSize: "12px", color: "#999" }}>
                      {notification?.body?.length}/150 characters
                    </div>
                  </GridLayoutItem>

                  <GridLayoutItem
                    col={1}
                    row={2}
                    className="form-inner"
                    style={{ marginTop: "40px" }}>
                    <div
                      className="row justify-content-center"
                      style={{ display: "flex" }}>
                      <div className="col k-pr-2" style={{ width: "100%" }}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}>
                          <div>
                            <Label className="form-heading">
                              Available Users:
                            </Label>
                          </div>

                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}>
                            <input
                              type="checkbox"
                              id="selectAll"
                              checked={selectAllUser}
                              onChange={(e) => {
                                let cloneUserList = [...userOption];
                                let updatedUserList = cloneUserList?.map(
                                  (item) => ({
                                    ...item,
                                    selected: e?.target?.checked ?? false,
                                  })
                                );
                                setUserOptions(updatedUserList ?? []);
                                setSelectAllUser(e?.target?.checked);
                              }}
                            />

                            <Label
                              htmlFor="selectAll"
                              style={{ fontSize: "14px" }}>
                              Select All
                            </Label>
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "1%",
                          }}>
                          <div style={{ width: "60%" }}>
                            {renderSearchInput()}
                          </div>
                          <div style={{ width: "40%" }}>
                            <DropDownList
                              data={filterOptions}
                              textField="text"
                              dataItemKey="value"
                              value={selectedFilter}
                              className="field-ip"
                              onChange={(e) =>
                                setSelectedFilter(e.target.value)
                              }
                            />
                          </div>
                        </div>

                        <div style={{ marginTop: "10px" }}>
                          {searchLoader ? (
                            <div
                              style={{
                                height: 300,
                                width: "110%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}>
                              <Loader
                                size={"medium"}
                                style={{ color: "#083DB8" }}
                              />
                            </div>
                          ) : (
                            <ListBox
                              style={{
                                height: 340,
                                width: "110%",
                              }}
                              data={userOption}
                              textField="name"
                              selectedField={SELECTED_FIELD}
                              onItemClick={(e) =>
                                handleItemClick(
                                  e,
                                  "userOption",
                                  "selectedUsers"
                                )
                              }
                              onDragStart={handleDragStart}
                              onDrop={handleDrop}
                              toolbar={() => (
                                <ListBoxToolbar
                                  size="large"
                                  tools={[
                                    // "moveUp",
                                    // "moveDown",
                                    "transferTo",
                                    "transferFrom",
                                    "transferAllTo",
                                    "transferAllFrom",
                                    "remove",
                                  ]}
                                  data={userOption}
                                  dataConnected={selectedUsers}
                                  onToolClick={handleToolBarClick}
                                />
                              )}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </GridLayoutItem>
                  <GridLayoutItem
                    col={2}
                    row={2}
                    className="form-inner"
                    style={{ marginTop: "40px" }}>
                    <div className="col k-pl-0">
                      <Label className="form-heading">Selected Users:</Label>
                      <ListBox
                        style={{ height: 380, width: "100%" }}
                        data={selectedUsers}
                        textField="name"
                        selectedField={SELECTED_FIELD}
                        onItemClick={(e) =>
                          handleItemClick(e, "selectedUsers", "userOption")
                        }
                        onDragStart={handleDragStart}
                        onDrop={handleDrop}
                      />
                    </div>
                  </GridLayoutItem>

                  <GridLayoutItem
                    col={1}
                    row={4}
                    className="form-inner"
                    style={{ marginTop: "200px" }}>
                    <div
                      className="k-d-flex"
                      style={{ gap: "10px", paddingBottom: "20px" }}>
                      <KendoButton
                        iconClass=""
                        size={16}
                        themeColor={"primary"}
                        buttonName="SEND"
                        onClick={sendNotification}
                      />
                    </div>
                  </GridLayoutItem>
                </GridLayout>
              )}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SendPushNotification;
