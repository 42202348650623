import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  DELETE_NOTIFICATION_LIST,
  FECTH_NOTIFICATION_CATEGORY,
  FECTH_NOTIFICATION_LIST,
  FETCH_USERS,
  SAVE_NOTIFICATION_CATEGORY,
  SAVE_NOTIFICATION_LIST,
} from "../constant/Apipath";
import { PostRequestCall } from "../apicall/PostRequest";
import { Input, Switch, TextArea } from "@progress/kendo-react-inputs";
import { useSelector } from "react-redux";
import { Form } from "@progress/kendo-react-form";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { Error, Label } from "@progress/kendo-react-labels";
import { toast } from "react-toastify";
import { Loader } from "@progress/kendo-react-indicators";
import AddPageLayout from "../addpagelayout/AddPageLayout";
import { MultiSelect } from "@progress/kendo-react-dropdowns";

const AddNotificationcategories = () => {
  const { dataId } = useParams();
  const isAddForm = dataId === "add-Categorie";
  const [showLoader, setShowLoader] = useState(false);
  const { loginData } = useSelector((state) => state.main);
  const [isFieldEdited, setIsFiledEdited] = useState(false);
  const [userOption, setUserOptions] = useState([]);
  const [selectUsers, setSelectUsers] = useState([]);
  const [filterUsers, setFilterUsers] = useState("");
  const [notificationListData, setNotificationListDataData] = useState({
    actionLink: "mybuilderid://screenName=",
    body: "",
    category: "",
    name: "",
    title: "",
    description: "",
  });
  useEffect(() => {
    if (!isAddForm) {
      getNotificationList();
    }
  }, []);

  const getNotificationList = async () => {
    let featureObj = { SearchList: [{ Id: dataId }] };
    const res = await PostRequestCall(
      FECTH_NOTIFICATION_CATEGORY,
      featureObj,
      loginData?.token
    );

    let resData = res?.data?.data?.[0] ?? {};

    setNotificationListDataData({
      ...notificationListData,
      ...resData,
      actionLink: resData?.actionUrl,
      body: resData?.body,
      category: resData?.category,
      name: resData?.name,
      title: resData?.title,
      description: resData?.description,
    });
  };

  const onInputChange = (e, type) => {
    let inputValue = e.target.value;
    if (type === "number") {
      inputValue = inputValue.replace(/\D/g, "");
    }
    setIsFiledEdited(true);
    setNotificationListDataData({
      ...notificationListData,
      [e.target.name]: inputValue,
    });
  };
  useEffect(() => {
    getUserdata(false);
  }, []);
  useEffect(() => {
    if (filterUsers !== "") {
      getUserdata(true);
    }
  }, [filterUsers]);
  const getUserdata = async (onFocus, searchTerm) => {
    try {
      const obj = JSON.stringify({
        PageNr: 1,
        NrOfRecPerPage: onFocus ? 20 : 0,
        FullSearch: "",
        UserId: loginData.userId,
        SearchList: onFocus ? [] : [{ name: searchTerm }],
        IncludeRecordNr: true,
      });
      const getResult = await PostRequestCall(
        FETCH_USERS,
        obj,
        loginData?.token
      );
      if (getResult?.data?.data?.length > 0) {
        const list = getResult?.data?.data.map((item) => ({
          value: item.id,
          label: item?.name,
        }));
        if (selectUsers?.length > 0) {
          const filteredProductOptions = list.filter(
            (data) => !ifUserSelected(data)
          );
          setUserOptions(filteredProductOptions);
        } else {
          setUserOptions(list);
        }
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  const ifUserSelected = (data) => {
    return selectUsers?.some(
      (selectedProduct) => selectedProduct?.value === data?.value
    );
  };
  const onUserchange = (event) => {
    const newValue = event?.value;
    setSelectUsers(newValue);
  };
  const onUserfilterchange = (event) => {
    setFilterUsers(event.filter.value);
  };
  const saveCurrentData = async () => {
    setShowLoader(true);

    // if (
    //   !notificationListData?.name ||
    //   !notificationListData?.description ||
    //   !notificationListData?.title ||
    //   !notificationListData?.body
    // ) {
    //   setShowLoader(false);
    //   toast.error("name, description, title, and body are required.", {
    //     position: "top-right",
    //   });
    //   return;
    // }

    const newCompany = {
      ReturnRecordId: true,
      ReturnRecordError: true,
      UserId: loginData?.userId,

      SaveList: [
        {
          Id: isAddForm ? 0 : dataId,
          Name: notificationListData?.name,
          Description: notificationListData?.description,
          ActionUrl: notificationListData?.actionLink,
          SaveInHistory: notificationListData?.saveInHistory,
          ModifiedBy: loginData?.userId,
        },
      ],
    };

    try {
      const result = await PostRequestCall(
        SAVE_NOTIFICATION_CATEGORY,
        newCompany,
        loginData?.token
      );

      if (result?.data?.status === false || result?.data?.status === 400) {
        setShowLoader(false);
        toast.error(
          result?.data?.message ??
            "Something went wrong. Please try again later.",
          {
            position: "top-right",
          }
        );
      } else {
        setShowLoader(false);
        toast.success(`Data ${!isAddForm ? "updated" : "added"} successfully`, {
          position: "top-right",
        });
        window.history.back();
      }
    } catch (error) {
      setShowLoader(false);
      toast.error("Something went wrong. Please try again later.", {
        position: "top-right",
      });
      console.error("Error saving data:", error);
    }
  };

  const onDeleteClick = async () => {
    try {
      setShowLoader(true);

      const deleteObj = {
        ReturnRecordError: true,
        ReturnRecordId: false,
        UserId: loginData?.userId,
        DeleteList: [
          {
            Id: Number(dataId),
            ModifiedBy: loginData?.userId,
          },
        ],
      };

      return PostRequestCall(
        DELETE_NOTIFICATION_LIST,
        deleteObj,
        loginData?.token
      )
        .then((res) => {
          setShowLoader(false);
          if (res?.status === 200) {
            toast.success(`Data delete successfully`, {
              position: "top-right",
            });

            window.history.back();
          } else {
            toast.error(
              res?.data?.message ??
                "Something went wrong. Please try again later.",
              {
                position: "top-right",
              }
            );
          }
        })
        .catch((error) => {
          setShowLoader(false);
          toast.error("Something went wrong. Please try again later.", {
            position: "top-right",
          });
          console.log("Error while deleting a record :: ", error);
        });
    } catch (error) {
      setShowLoader(false);
      toast.error("Something went wrong. Please try again later.", {
        position: "top-right",
      });
      console.log("Error while deleting a record :: ", error);
    }
  };

  if (showLoader) {
    return (
      <Loader
        type="converging-spinner"
        className="kendo-spinner"
        style={{
          display: "flex",
          justifyContent: "center",
          minHeight: "500px",
          alignItems: "center",
        }}
      />
    );
  }

  return (
    <div className="add_form_height_95">
      <AddPageLayout
        dataId={dataId}
        addForm={isAddForm}
        showFooter={false}
        isFieldEdited={isFieldEdited}
        recordText={"Notification Categorie Id"}
        saveCurrentData={saveCurrentData}
        hideDeleteBtn={true}>
        <div style={{ width: "100%" }}>
          <Form
            render={() => (
              <GridLayout
                rows={[
                  { height: 70 },
                  { height: 70 },
                  { height: 130 },
                  { height: 200 },
                ]}
                cols={[{ width: 375 }, { width: 375 }, { width: 375 }]}
                gap={{ rows: 30, cols: 35 }}
                style={{ padding: "16px" }}>
                <GridLayoutItem col={1} row={1} className="form-inner">
                  <Label className="form-heading"> Name:</Label>
                  <Input
                    name="name"
                    value={notificationListData?.name}
                    className="field-ip"
                    placeholder="Enter name"
                    onChange={onInputChange}
                  />
                  <Error></Error>
                </GridLayoutItem>

                <GridLayoutItem col={2} row={1} className="form-inner">
                  <Label className="form-heading"> Action Link:</Label>
                  <Input
                    name="actionLink"
                    value={notificationListData?.actionLink}
                    className="field-ip"
                    onChange={onInputChange}
                    placeholder="Enter action link"
                  />
                  <Error></Error>
                </GridLayoutItem>
                <GridLayoutItem col={3} row={1} className="form-inner">
                  <label className="form-heading">Save In History :</label>
                  <div>
                    <Switch
                      size={"small"}
                      checked={notificationListData.saveInHistory}
                      onChange={(e) => {
                        setIsFiledEdited(true);
                        setNotificationListDataData((preValue) => ({
                          ...preValue,
                          saveInHistory: e?.value,
                        }));
                      }}
                    />
                  </div>
                </GridLayoutItem>
                <GridLayoutItem col={1} row={2} className="form-inner">
                  <label className="form-heading">Description:</label>
                  <div style={{ display: "flex", gap: "2px" }}>
                    <TextArea
                      type="text"
                      id="description"
                      name="description"
                      value={notificationListData?.description}
                      className="field-ip"
                      placeholder="Enter description"
                      onChange={(e) => onInputChange(e)}
                      rows={6}
                    />
                  </div>
                </GridLayoutItem>
              </GridLayout>
            )}
          />
        </div>
      </AddPageLayout>
    </div>
  );
};

export default AddNotificationcategories;
