import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  DELETE_COMPANIES_COMPANIES,
  DELETE_POSTS_COMMENTS,
  FETCH_COMPANIES,
  FETCH_POSTS_COMMENTS,
  SAVE_POSTS_COMMENTS,
  SAVE_USERS_COMPANIES,
} from "../../constant/Apipath";
import { PostRequestCall } from "../../apicall/PostRequest";
import { Input, Switch, TextArea } from "@progress/kendo-react-inputs";
import { useSelector } from "react-redux";
import { Form } from "@progress/kendo-react-form";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { Label } from "@progress/kendo-react-labels";
import { IconComponent } from "../../common/Icon";
import { toast } from "react-toastify";
import { Loader } from "@progress/kendo-react-indicators";
import AddPageLayout from "../../addpagelayout/AddPageLayout";
import UserSearchInput from "../../common/UserSearchInput";
import PostIdSearchInput from "../../common/PostIdSearchInput";
import RecordStatusInput from "../../common/RecordStatusInput";
import KendoButton from "../../common/KendoButton";
import SiteTypeInput from "../../common/SiteTypeInput";

const Addcompany = () => {
  const { companyId } = useParams();
  const { loginData } = useSelector((state) => state.main);
  const isAddForm = Number(companyId) === 0;

  let navigate = useNavigate();

  const [company, setCompany] = useState({
    webSite: "",
    countryCode: "",
    phone: "",
    industryName: "",
    industryId: 0,
    manufacturer: "",
    recordStatusId: 1,
    recordStatus: "New",
  });

  const [showLoader, setShowLoader] = useState(false);
  const [isFieldEdited, setIsFiledEdited] = useState(false);
  const [footerInfo, setFooterInfo] = useState({});

  useEffect(() => {
    if (!isAddForm) {
      getCompanyDetails();
    }
  }, []);

  const getCompanyDetails = async () => {
    try {
      let obj = { SearchList: [{ Id: companyId }] };
      const fetchcompany = await PostRequestCall(
        FETCH_COMPANIES,
        obj,
        loginData?.token
      );

      let resData = fetchcompany?.data?.data?.[0] ?? {};
      setCompany((preValue) => ({
        ...preValue,
        name: resData?.name,
        webSite: resData?.webSite,
        countryCode: resData?.countryCode,
        phone: resData?.phone,
        industryName: resData?.industryName,
        industryId: resData?.industryId,
        manufacturer: resData?.manufacturer,
        recordStatus: resData?.recordStatus,
        recordStatusId: resData?.recordStatusId,
      }));
      let footerObj = {
        dateCreated: resData?.dateCreated ?? null,
        modifiedByUser: resData?.modifiedByUser ?? "",
        dateModified: resData?.dateModified ?? "",
        createdByUser: resData?.createdByUser ?? "",
      };
      setFooterInfo(footerObj);
    } catch (error) {
      console.log("Error while getting post comment details :: ", error);
    }
  };

  const onInputChange = (e, number) => {
    let inputValue = e.target.value;
    setCompany({
      ...company,
      [e.target.name]: inputValue,
    });
    setIsFiledEdited(true);
  };

  const onNumberInputChange = (e) => {
    let inputValue = e.target.value;
    if (/^\d*$/.test(inputValue)) {
      setCompany({
        ...company,
        [e.target.name]: inputValue,
      });
      setIsFiledEdited(true);
    }
  };

  function navigateToWebsite() {
    const websiteUrl = document.getElementById("webSite").value;
    if (websiteUrl) {
      window.open(websiteUrl, "_blank");
    }
  }

  const saveCurrentData = async () => {
    if (company?.name?.trim() === "") {
      return toast.error("Please enter a valid company name", {
        position: "top-right",
      });
    }

    setShowLoader(true);
    const newCompany = {
      UserId: loginData?.userId,
      ReturnRecordId: true,
      ReturnRecordError: true,
      SaveList: [
        {
          Id: isAddForm ? 0 : companyId,
          Name: company?.name ?? "",
          RecordStatusId: company?.recordStatusId || 1,
          WebSite: company?.webSite ?? "",
          CountryCode: company?.countryCode || "",
          Phone: company?.phone,
          Manufacturer: company?.manufacturer || false,
          IndustryId: company?.industryId ?? 0,
          Active: false,
          ModifiedBy: loginData?.userId,
        },
      ],
    };
    try {
      const res = await PostRequestCall(
        SAVE_USERS_COMPANIES,
        newCompany,
        loginData?.token
      );

      if (res?.data?.status === false || res?.data?.status === 400) {
        setShowLoader(false);
        let errorMessage = res?.data?.errorMessage
          ? res?.data?.errorMessage
          : res?.data?.error
          ? res?.data?.error
          : "Something went wrong. Please try again later.";
        toast.error(errorMessage, {
          position: "top-right",
        });
      } else {
        toast.success(`Data ${!isAddForm ? "updated" : "added"} successfully`, {
          position: "top-right",
        });
        // window.history.back();
        navigate("/companies/companies");

        setShowLoader(false);
      }
    } catch (error) {
      setShowLoader(false);
      toast.error("Something went wrong. Please try again later.", {
        position: "top-right",
      });
      console.error("Error saving data :", error);
    }
  };

  const onDeleteClick = async () => {
    try {
      setShowLoader(true);
      const deleteObj = {
        ReturnRecordError: true,
        ReturnRecordId: false,
        UserId: loginData.userId,
        DeleteList: [
          {
            Id: companyId,
            ModifiedBy: loginData.userId,
          },
        ],
      };
      return PostRequestCall(
        DELETE_COMPANIES_COMPANIES,
        deleteObj,
        loginData?.token
      )
        .then((res) => {
          setShowLoader(false);
          if (res?.data?.status) {
            toast.success("Data deleted successfully", {
              position: "top-right",
            });
            // window.history.back();
            navigate("/companies/companies");
          } else {
            toast.error(
              res?.data?.message ??
                "Something went wrong. Please try again later.",
              {
                position: "top-right",
              }
            );
          }
        })
        .catch((error) => {
          setShowLoader(false);
          toast.error("Something went wrong. Please try again later.", {
            position: "top-right",
          });
          console.log("Error while deleting a record :: ", error);
        });
    } catch (error) {
      setShowLoader(false);
      toast.error("Something went wrong. Please try again later.", {
        position: "top-right",
      });
      console.log("Error while deleting a record :: ", error);
    }
  };

  if (showLoader) {
    return (
      <Loader
        type="converging-spinner"
        className="kendo-spinner"
        style={{
          display: "flex",
          justifyContent: "center",
          minHeight: "500px",
          alignItems: "center",
        }}
      />
    );
  }

  return (
    <>
      <div className="add_form_height_100">
        <AddPageLayout
          dataId={companyId}
          addForm={isAddForm}
          showFooter={!isAddForm}
          footerInfo={footerInfo}
          isFieldEdited={isFieldEdited}
          recordText={"Company ID"}
          saveCurrentData={saveCurrentData}
          onDeleteClick={onDeleteClick}>
          <div style={{ height: "100%" }}>
            <Form
              render={() => (
                <GridLayout
                  rows={[
                    {
                      height: 70,
                    },
                  ]}
                  cols={[
                    {
                      width: 375,
                    },
                    {
                      width: 375,
                    },
                    {
                      width: 375,
                    },
                  ]}
                  gap={{
                    rows: 30,
                    cols: 35,
                  }}
                  style={{ padding: "16px" }}>
                  <GridLayoutItem col={1} row={1} className="form-inner">
                    <label className="form-heading">Name:</label>
                    <Input
                      type="text"
                      id="name"
                      name="name"
                      value={company?.name}
                      className="field-ip"
                      onChange={(e) => onInputChange(e)}
                    />
                  </GridLayoutItem>

                  <GridLayoutItem col={2} row={1} className="form-inner">
                    <label className="form-heading">WebSite:</label>
                    <div style={{ display: "flex", gap: "2px" }}>
                      <Input
                        type="text"
                        id="webSite"
                        name="webSite"
                        value={company?.webSite}
                        className="field-ip"
                        onChange={(e) => onInputChange(e)}
                      />
                      <KendoButton
                        onClick={navigateToWebsite}
                        className="navigate-button"
                        buttonName="-->"
                        disabled={!company?.webSite}
                      />
                    </div>
                  </GridLayoutItem>

                  <GridLayoutItem col={3} row={1} className="form-inner">
                    <label className="form-heading">Country Code:</label>
                    <Input
                      type="text"
                      id="countryCode"
                      name="countryCode"
                      value={company?.countryCode}
                      className="field-ip"
                      onChange={(e) => onInputChange(e)}
                    />
                  </GridLayoutItem>

                  <GridLayoutItem col={1} row={2} className="form-inner">
                    <label className="form-heading">Phone:</label>
                    <Input
                      id="phone"
                      name="phone"
                      value={company?.phone}
                      className="field-ip"
                      onChange={(e) => onNumberInputChange(e)}
                    />
                  </GridLayoutItem>

                  <GridLayoutItem col={2} row={2} className="form-inner">
                    <SiteTypeInput
                      value={company?.industryName}
                      setSelectedSiteType={({ label, id }) => {
                        setIsFiledEdited(true);
                        setCompany((preValue) => ({
                          ...preValue,
                          industryName: label,
                          industryId: id,
                        }));
                      }}
                    />
                  </GridLayoutItem>

                  <GridLayoutItem col={3} row={2} className="form-inner">
                    <label className="form-heading">Manufacturer:</label>
                    <div>
                      <Switch
                        size={"small"}
                        checked={company.manufacturer}
                        onChange={(e) => {
                          setIsFiledEdited(true);
                          setCompany((preValue) => ({
                            ...preValue,
                            manufacturer: e?.value,
                          }));
                        }}
                      />
                    </div>
                  </GridLayoutItem>

                  {!isAddForm && (
                    <GridLayoutItem col={1} row={3} className="form-inner">
                      <RecordStatusInput
                        recordStatusId={company?.recordStatusId}
                        recordStatus={company?.recordStatus}
                        onChange={({ recordStatus, recordStatusId }) => {
                          setIsFiledEdited(true);
                          setCompany((preValue) => ({
                            ...preValue,
                            recordStatus,
                            recordStatusId,
                          }));
                        }}
                      />
                    </GridLayoutItem>
                  )}
                </GridLayout>
              )}
            />
          </div>
        </AddPageLayout>
      </div>
    </>
  );
};

export default Addcompany;
