import React, { useEffect, useState } from "react";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Label } from "@progress/kendo-react-labels";
import { useSelector } from "react-redux";
import { PostRequestCall } from "../apicall/PostRequest";
import { FETCH_COUNTRIES } from "../constant/Apipath";

const CountryInput = (props) => {
  const [countryOptions, setCountryOptions] = useState([]);
  const { loginData } = useSelector((state) => state.main);

  useEffect(() => {
    getCountry();
  }, []);

  const getCountry = async () => {
    try {
      var data = JSON.stringify({
        PageNr: 1,
        NrOfRecPerPage: 100,
        FullSearch: "",
        SortList: [{ FieldName: "", Direction: "" }],
        IncludeRecordNr: true,
      });
      const result = await PostRequestCall(
        FETCH_COUNTRIES,
        data,
        loginData?.token
      );
      let countryData = result?.data?.data?.map((item) => ({
        id: item?.name,
        text: item?.name,
      }));
      setCountryOptions([...countryData]);
    } catch (error) {
      console.log("Error while getting  data :: ", error);
    }
  };

  return (
    <>
      <Label className="form-heading">Country :</Label>
      <div>
        <DropDownList
          style={{ width: "370px" }}
          onChange={(e) => {
            let country = e.value?.text;
            let countryId = e.value?.id;

            props?.onChange({ country, countryId });
          }}
          className="dropdown-list"
          value={{
            id: props?.countryId,
            text: props?.country,
          }}
          data={countryOptions}
          textField="text"
          dataItemKey="id"
        />
      </div>
    </>
  );
};

export default CountryInput;
